.order-tracking-progress{
    position: relative;
    padding: 0 1rem 0 3.5rem;
    margin: 2rem 0 0;
    list-style: none;
  }
  
  .progress-item {
    position: relative;
    min-height: 70px;
    counter-increment: list;
    padding-left: 0.5rem;
  }
  .progress-item:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: -1.2rem;
    top: 0px;
    height: 100%;
    width: 1px;
    border-left: 1px dashed var(--quaternary-color);
  }

  
.progress-item.progress-item-active:before{
    border-left: 1px solid var(--primary-color);
}
  .progress-item:after {
    content: counter(list);
    position: absolute;
    top: 0;
    left: -2.3rem;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: var(--secondary-color);
    color: var(--quaternary-color);
    font-weight: 400;
    font-size: 13px;
    line-height: 2rem;
    text-align: center;
    border: 1px solid var(--quaternary-color);
  }
  .progress-item:last-child:before {
    border: none;
  }
  .progress-item.progress-item-completed {
    color:var(--primary-color);
  }
  .progress-item.progress-item-completed:after {
    content: "\2713";
    font-weight: 400;
    background: var(--primary-color);
    color: var(--secondary-color);
  }
  .progress-item.progress-item--active:after {
    background: #fff;
    color: var(--quaternary-color);
  }
  
  .progress-title {
    padding: 0.4rem 0 0.5rem;
    margin: 0;
    font-size: 1.5rem;
  }
  
  .progress-info {
    font-size: 1.1em;
    font-weight: 600;
    color: var(--quaternary-color);
    margin-bottom: 0;
    line-height: 2.2;
  }
  

.order-tracking-sec{
    padding: 4em 0;
}

.order-tracking-header{
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 2em;
}

.order-tracking-header h4{
    color: var(--tertiary-color);
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0em;
}

.back-btn {
    background-color: var(--tertiary-color)!important;
    padding: 0.6em 1em !important;
    text-align: center;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    border-radius: 5px!important;
    color: var(--secondary-color) !important;
    border: 1px solid var(--tertiary-color)!important;
    font-size: 1em!important;
    font-weight: 500!important;
    display: flex;
    align-items: center;
    gap:0.5em;
  }

  .back-btn svg{
    fill: var(--secondary-color);
    width: 1.2em;
  }
  
  .back-btn:hover,
  .back-btn:focus,
  .back-btn:active {
    background-color:var(--tertiary-color)!important;
    color: var(--secondary-color)!important;
    box-shadow: none!important;
  }

  .order-tracking-box{
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 10px;
    padding: 2em;
    margin:2em 0;
  }

  .order-tracking-header-info{
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .order-tracking-action-btn{
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .order-detail-box{
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 10px;
    padding: 2em;
    margin:2em 0;
    display: flex;
    gap:2em;
    justify-content: space-between;
  }

.order-detail-box .address-details{
    display: flex;
    flex-direction: column;
    gap: 1.1em;
}
.order-detail-box .address-details h4{
    font-size: 1.3em;
    font-weight: 700;
    margin: 0;
    color: var(--tertiary-color);
}
.order-detail-box .address-details p{
    font-size: 1.2em;
    line-height: 1.8;
    margin: 0;
    max-width: 260px;
    color: var(--tertiary-color);
    font-weight: 400;
}

.order-tracking-summary-sec h3 {
    font-size: 1.1em;
    font-weight: 700;
}

.cart-summary-total-amount {
  padding-top: 1em;
}

.cart-summary-total-amount h5 {
  align-items: center;
  color: var(--tertiary-color);
  display: flex;
  font-size: 1em;
  font-weight: 600;
  justify-content: space-between;
}

.cart-summary-header {
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding-bottom: 0em;
}

.cart-summary-header p {
  align-items: center;
  color: var(--quaternary-color);
  display: flex;
  font-size: 0.9em;
  font-weight: 400;
  justify-content: space-between;
}

.cart-summary-header p span {
  color: var(--tertiary-color);
}

.address-details h4{
  font-size: 1em;
  font-weight: 700;
}

.address-details p{
  max-width: 400px;
  font-size: 0.8em;
  color: var(--tertiary-color);
  font-weight: 400;
  line-height: 1.8;
  margin: 0;
}

.order-detail-box .order-product-details{
    display: flex;
    gap:2em;
    flex-direction: row;
}

.order-pricing-details{
    text-align: right;
}

.order-tracking-summary-sec {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 10px;
    padding: 2em;
    margin:2em 0;
    margin-bottom: 0;
    display: flex;
    gap:2em;
    justify-content: space-between;
}

.order-tracking-amount-sec{
    width: 25%;
}

.order-tracking-amount-sec h3{
    margin-bottom: 1em;
}

.order-tracking-status-box{
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 10px;
    padding: 2em;
    margin:2em 0;
    display: flex;
    gap:2em;
    justify-content: space-between;
}

.order-tracking-status-card{
    display: flex;
    gap:0.5em;
    font-size: 1em;
    font-weight: 500;
}

.order-tracking-status-card span{
    font-weight: 600;
    color: var(--primary-color);
}

.address-details{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.checkout-product-content p span {
  color: var(--tertiary-color);
  font-weight: 400;
  padding-left: 1em;
}

.order-list-table {
  margin: 2em 0 0;
}

.order-product-img {
  display: flex;
  align-items: center;
  gap: 1em;
}

.order-product-img h4 {
  font-size: 1em;
  margin: 0;
  color: var(--quaternary-color) !important;
}

.order-list-table .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: : transparent;
}

.order-list-table .table-bordered>:not(caption)>* {
  border-width: 0;
}

.order-list-table .table-bordered>:not(caption)>*>* {
  border-width: 0;
}

.order-list-table table thead {
  background-color: var(--primary-color);
}

.order-list-table table thead tr th {
  vertical-align: middle;
  font-size: 1em;
  font-weight: 400;
  /* white-space: nowrap; */
  padding: 1em;
}

.order-list-table table tbody tr td {
  padding: 1.5em 1em;
  vertical-align: middle;
  color: #4F4E55 !important;
  font-size: 1em;
  font-weight: 300;

}

.order-list-table .table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: unset;
}

.order-list-table table tbody tr td h5 {
  color: var(--quaternary-color) !important;
  margin: 0;
  font-size: 1em;
  font-weight: 400;
}

.action-btn {
  background: #FF3C21;
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  font-weight: 600;
}

.action-btn a {
  color: var(--secondary-color);
}

.action-btn a:hover {
  color: var(--secondary-color);
}


.order-list-table table thead tr th{
  color: var(--secondary-color);
  background-color: #000;
}

.order-list-table table thead tr th:last-child{
  text-align: center;
}

.order-list-table table tbody tr td:last-child{
  text-align: center;
}

.progress-info div{
  font-size: 0.8em;
  font-weight: 400;
}