.contact-us-header-sec{
    background-image: url('contact-us.png');
    background-size: cover;
    height: 20em;
    background-repeat: no-repeat;
    position: relative;
}

.contact-us-header-sec::before{
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right:0;
    background-color: rgba(0,0,0,0.8);
}

.contat-us-title{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20em;
    position: relative;
}

.contat-us-title h1{
    font-size: 2.5em;
    font-weight: 700;
    color: var(--secondary-color);
}

.contact-us-form-card{
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2em;
    border-radius: 10px;
    height: 100%;
}

.contact-us-form-box{
    padding: 4em 0;
}

.contact-us-form-card h2{
    font-size: 2em;
    font-weight: 600;
    color: var(--tertiary-color);
}

.contact-us-form-card p{
    font-size: 1em;
    color: var(--quaternary-color);
    font-weight: 400;
    margin-bottom: 2em;
}

.conta-us-img{
    height: 37em;
    width: 95%;
    object-fit: cover;
    border-radius: 10px;
}

.section-title h5{
    font-size: 1em;
    font-weight: 500;
    color: var(--quaternary-color);
}

.section-title{
    text-align: center;
}

.section-title h2{
    font-size: 2em;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 1em;
    line-height: 1.4;
   
}
.section-title::after h2{
    content: '';
  position: absolute;
  width: 100%;
  display: block;
  height: 5px;
  background-color: red;
  bottom: -5px;
}

.contact-us-location-grid{
    padding: 4em 0;
}

.contact-us-location-card{
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    gap: 2em;
    justify-content: center;
    text-align: center;
}

.contact-us-location-info h4{
    font-size: 1.2em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 1em;
}

.contact-us-location-info p{
    font-size: 0.9em;
    font-weight: 600;
    color: var(--quaternary-color);
}
.contact-us-social-link{
    padding: 4em 0;
    display: flex;
}
.contact-us-social-link hr{
    padding-top: 22px;
}
.social-link-media ul{
    display: flex;
    list-style-type: none;
    gap: 1em;
}
.social-link-sec-1{
    display: flex;

}
.our-services-header-sec{
    background-image: url('contact-us.png');
    background-size: cover;
    height: 20em;
    background-repeat: no-repeat;
    position: relative;
}
.our-services-header-sec::before{
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right:0;
    background-color: rgba(0,0,0,0.8);
}
.our-services-box{
    padding:4em 3em;
    background-color:#F4F4F4;

}
.our-services-title{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20em;
    position: relative;
}

.our-services-title h1{
    font-size: 2.5em;
    font-weight: 700;
    color: var(--secondary-color);
}
.our-services-img{
    height:31.25em;
    width: 31.25em;
    object-fit: cover;
    border-radius: 10px;
}
.our-services-we-have{
    padding-top: 3em;
}
.our-services-we-have h4{
    font-size: 2em;
    font-weight: 700;
    color: var(--primary-color);
    padding-bottom: 1.5em;
}
.our-services-we-have p{
    font-size: 1em;
    color: var(--quaternary-color);
    font-weight: 400;
    margin-bottom: 2em;
}
.our-services-btn{
    padding-top: 2em;
}
.our-services-options-grid{
    padding: 3em 0em;
}
.our-services-grid{
    padding: 3em 0em;
}
.our-services-box-bottom{
    padding:4em 3em;
    background-color:#F4F4F4;
    gap: 100px;
}
.our-services-bottom-img{
    height:31.25em;
    width: 31.25em;
    object-fit: cover;
    border-radius: 10px;
}
.our-services-bottom-we-have{
    padding-top: 3em;
}
.our-services-options-info{
padding-top: 0.9em;
}
.our-services-bottom-we-have h4{
    font-size: 2em;
    font-weight: 700;
    color: var(--primary-color);
    padding-bottom: 1.5em;
    
}
.our-services-bottom-we-have p{
    font-size: 1em;
    color: var(--quaternary-color);
    font-weight: 400;
    margin-bottom: 2em;

}
.our-services-bottom-img-sec{
    padding-left: 6em;

}
.our-services-bottom-btn{
    padding-top: 2em;
}
.our-services-options-info h4{
font-size: 0.9em;
}
.our-services-options-card{
    display: flex;
    gap: 30px;
    padding: 30px;
}

.privacy-policy-sec-header{
    background-image: url('contact-us.png');
    background-size: cover;
    height: 20em;
    background-repeat: no-repeat;
    position: relative;
}
.privacy-policy-sec-header::before{
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right:0;
    background-color: rgba(0,0,0,0.8);
}
.privacy-policy-title{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20em;
    position: relative;
}
.privacy-policy-title h1{
    font-size: 2.5em;
    font-weight: 700;
    color: var(--secondary-color);
}
.privacy-policy-who-we-are{
    padding: 3em 0em;
}
.privacy-policy-who-we-are h1{
    font-size: 1em;
   font-weight: 700;
}
.privacy-policy-who-we-are h3{
    font-size: .8em;
    font-weight: 700;
    padding-bottom: 1em;
    padding-top: 1em;
}
.privacy-policy-who-we-are p{
    font-size: 1em;
    color: var(--quaternary-color);
    font-weight: 400;
}
.terms-condition-sec-header{
    background-image: url('contact-us.png');
    background-size: cover;
    height: 20em;
    background-repeat: no-repeat;
    position: relative;
}
.terms-condition-sec-header::before{
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right:0;
    background-color: rgba(0,0,0,0.8);
}
.terms-condition-title{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20em;
    position: relative;
}
.terms-condition-title h1{
    font-size: 2.5em;
    font-weight: 700;
    color: var(--secondary-color);
}
.terms-condition-who-we-are{
    padding: 2em 1em;
    height: 235em;
}
.terms-condition-who-we-are h1{
    font-size: 1em;
   font-weight: 700;
}
.terms-condition-who-we-are h3{
    font-size: .8em;
    font-weight: 700;
    padding-bottom: 1em;
    padding-top: 1em;
}
.terms-condition-who-we-are p{
    font-size: 1em;
    color: var(--quaternary-color);
    font-weight: 400;
   
}