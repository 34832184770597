.profile-sec {
    padding: 3em 0;
}

.sidebar-box {
    /* background-color: #0093F0; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    /* height: 100%; */
    width: 100%;
    min-height: 70vh;

}

.account-main-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2em;
    min-height: 70vh
}

.sidebar-list-sec {
    margin-bottom: 0;
}


.sidebar-list-sec li a {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    color: #203C3D;
}

.sidebar-list-sec li a svg {
    fill: #203C3D;
}

.sidebar-list-sec li {
    padding: 1em 1em;
}

.sidebar-list-sec li.active {
    background-color: var(--primary-theme);
    /* border-radius: 10px; */
}
.search-no-dataicon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.search-no-img{
    width: 10em;
    height: 10em;
    margin: 0 auto;
    margin-top: 2em;
}
.add-money-amount-details .text-danger{
    position: absolute;
}

.sidebar-list-sec li.active svg {
    fill: var(--secondary-color);
}

.sidebar-list-sec li.active a span {
    color: var(--secondary-color);
}

.sidebar-list-sec li a span {
    font-size: 0.9em;
    font-weight: 600;
}

.profile-header-sec h3 {
    color: var(--tertiary-color);
    font-size: 1.1em;
    font-weight: 600;
}

.profile-header-sec p {
    color: var(--quaternary-color);
    font-size: 0.9em;
    font-weight: 400;
}

.profile-upload-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.profile-upload-img-sec {
    position: relative;
}

.profile-upload-img-sec .image-upload>input {
    display: none;
}

.profile-upload-img-sec .image-upload {
    position: absolute;
    top: 10px;
    left: 5em;
    background: #fff;
    border-radius: 30%;
    padding: 2px;
}

.profile-upload-img-sec {
    margin-bottom: 2em;
}

.account-form .form-label {
    font-size: 0.9em;
    font-weight: 600;
    color: var(--tertiary-color);
}

.account-form .form-label sup {
    font-size: 1.1em;
}

.account-form .form-control {
    background-color: #fff !important;
    border: 0 !important;
    height: 45px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.account-form .input-group-text {
    background-color: #fff !important;
    border: 0 !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.account-form .form-control::placeholder {
    color: var(--quaternary-color) !important;
    font-size: 0.9em;
}

.account-form .form-control:focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
}

.two-step-auth-header-sec {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1em 0;
    padding-bottom: 2em;
}

.two-step-auth-text {
    max-width: 500px;
}

.two-step-auth-text p {
    color: var(--tertiary-color);
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0;
}

.account-btn-sec {
    margin-top: 2em;
}

.caution-sec {
    color: var(--quaternary-color);
    font-size: 0.9em;
    font-weight: 400;
    max-width: 550px;
    margin-bottom: 1em;
}

.caution-sec span {
    color: red;
    font-weight: 400;
    text-transform: uppercase;
}

.notify-para {
    color: var(--quaternary-color);
    font-size: 0.85em;
    font-weight: 400;
    max-width: 300px;
}

.notification-para {
    color: var(--quaternary-color);
    font-size: 0.85em;
    font-weight: 400;
    max-width: 350px;
    margin-bottom: 0;
}

.notification-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2em;
    margin-bottom: 1.5em;
}

.two-step-auth-header-sec .form-switch .form-check-input {
    height: 1.5em;
    width: 3em;
}

.form-check-input{
    height: 1.5em!important;
    width: 3em!important;
}

.form-check-input:checked {
    background-color: var(--primary-color)!important;
    border-color: var(--primary-color)!important;
}

.form-check-input:focus {
    box-shadow: none!important;
    outline: none!important;
}

.account-form .input-group .btn{
    padding: 0;
}

.toggle input[type=checkbox] {
    display: none;
}

.toggle label {
    color: #4FBCA1;
    position: relative;
}

.toggle input[type=checkbox]+label::before {
    content: " ";
    display: block;
    width: 3.8em;
    height: 1.8em;
    border: 1px solid #ddd;
    border-radius: 50px;
    position: absolute;
    top: 0px;
    left: -65px;
    background: #ddd;
}

.toggle input[type=checkbox]+label::after {
    content: " ";
    display: block;
    height: 20px;
    width: 20px;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 4.75px;
    left: -60px;
    background: #fff;
    transition: all 0.3s ease-in;
    -webkit-box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
    box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
}

.toggle input[type=checkbox]:checked+label::before {
    background: #0093F0;
    border-color: #0093F0;
}

.toggle input[type=checkbox]:checked+label::after {
    left: -30px;
    transition: all 0.3s ease-in;
}

.notification-item {
    position: relative;
}

.toggle {
    margin-top: -18px;
}

/* My Orders */

.delivery-status[aria-expanded="true"] .deliver-status-card img {
    transform: rotate(180deg);
}

.deliver-status-card {
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 2em;
    cursor: pointer;
}

.deliver-status-card h4 {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    gap: 0.5em;
    margin: 0;
    font-size: 1em;
}

.deliver-status-card h4 span {
    color: var(--tertiary-color);
    font-size: .9em;
}

.deliver-status-card h4,
.order-notes span {
    color: var(--quaternary-color);
    font-weight: 500;
}

.deliver-status-card img {
    max-width: 1.2em;
}

.order-notes {
    color: #000 !important;
    font-size: 1em !important;
    font-weight: 500;
    margin: 0 0 2em !important;
}

.delivery-address {
    display: flex;
    gap: 1em;
}

.deliver-coupon,
.deliver-shipping,
.delivery-payment {
    flex: 1 1;
}

.delivery-title h3 {
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 600;
    margin: 0 0 1em;
}

.delivery-payment h4 {
    color: var(--quaternary-color);
    display: flex;
    font-size: 0.9em;
    font-weight: 500;
    gap: 0.5em;
    margin: 0 0 0.8em;
}

.delivery-payment h4 span {
    color: var(--tertiary-color);
    font-size: .8em;
}

.deliver-shipping h4 {
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 500;
    gap: 0.5em;
}

.deliver-coupon p,
.deliver-shipping p {
    color: var(--quaternary-color);
    font-weight: 500;
    line-height: 1.8;
    font-size: 0.9em;
}

.download-receipt-btn {
    border: 1px solid #005479 !important;
    background-color: #005479 !important;
    border-radius: 5px !important;
    color: #fff !important;
    font-size: 0.9em !important;
    font-weight: 500 !important;
    overflow: hidden;
    padding: 0.6em 1em !important;
    position: relative;
    text-align: center;
    transition: all .5s ease-in-out;
    display: inline-block;
}


.download-receipt-btn:hover,
.download-receipt-btn:focus,
.download-receipt-btn:active {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    background-color: var(--primary-color) !important;
    box-shadow: none !important;
    color: var(--secondary-color) !important;
    /* -webkit-transform: translate3d(0,-5px,0);
    -o-transform: translate3d(0,-5px,0);
    -ms-transform: translate3d(0,-5px,0);
    -moz-transform: translate3d(0,-5px,0);
    transform: translate3d(0,-5px,0); */
}


.wishlist-btn {
    border: 1px solid #ffb52d !important;
    background-color: #ffb52d!important;
    border-radius: 5px !important;
    display: inline-block !important;
    color: var(--secondary-color) !important;
    font-size: 0.9em !important;
    font-weight: 500 !important;
    overflow: hidden;
    padding: 0.6em 1em !important;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .5s ease-in-out;
}

.wishlist-btn:active,
.wishlist-btn:focus,
.wishlist-btn:hover {
    border: 1px solid var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    color: var(--secondary-color) !important;
}

.deliver-coupon {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-around;
    text-align: end;
}

.deliver-status-sec {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.deliver-status-sec h3 {
    color: var(--primary-color);
    font-size: 1.2em;
    margin: 0;
}

.checkout-product-box {
    align-items: center;
    display: flex;
    gap: 2em;
}

.search-btn,
.search-btn:active,
.search-btn:focus,
.search-btn:hover {
    background-color: var(--tertiary-color) !important;
    color: var(--secondary-color) !important;
}

.search-btn {
    border: 1px solid var(--tertiary-color) !important;
    border-radius: 5px !important;
    display: inline-block !important;
    font-size: .9em !important;
    font-weight: 500 !important;
    overflow: hidden;
    padding: 0.6em 1em !important;
    position: relative;
    text-align: center;
    transition: all .5s ease-in-out;
}

.other-product-details {
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.other-product-box {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    justify-content: space-between;
    padding: 2em 0;
    align-items: center;
}

.other-product-box .checkout-product-box {
    border-bottom: 0;
    padding: 0;
}

.checkout-product-img-sec {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 2em 4em;
}

.checkout-product-img {
    width: 141px;
    height: 144px;
    object-fit: contain;
}

.checkout-product-content {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.checkout-product-content h4 {
    color: var(--tertiary-color);
    font-size: 0.9em;
    font-weight: 700;
    margin: 0;
}

.checkout-product-content p {
    color: var(--quaternary-color);
    font-size: 0.9em;
    font-weight: 500;
    margin: 0;
}

.checkout-product-content h5 {
    color: var(--tertiary-color);
    font-size: 0.9em;
    font-weight: 700;
    margin: 0;
}

.checkout-product-content h5 span {
    font-size: 1em;
    font-weight: 500;
    padding-left: 1em;
    text-decoration: line-through;
}

.my-order-collapse-sec-box {
    margin-top: 2em;
}

.write-review-modal .modal-content {
    border-radius: 10px;
    background: #f4f4f4;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.write-review-modal .modal-header .h4 {
    margin-bottom: 0;
    font-size: 1.2em;
    font-weight: 600;
    color: var(--tertiary-color);
}

.write-review-modal .modal-header .btn-close {
    margin-top: 0.1em;
    position: relative;
    margin-bottom: 0.1em;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url('data:image/svg+xml;utf8,<svg stroke="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M1.458 1.458l21.084 21.084m0-21.084L1.458 22.542" /></svg>');
    font-size: 0.9em;
}

.write-review-modal form label {
    color: var(--tertiary-color);
    font-weight: 600;
    font-size: 1em;
    margin: 0.5em 0 0.5em;
}

.write-review-modal form label span {
    font-size: 0.8em;
    color: var(--quaternary-color);
}

.write-review-modal form textarea {
    background: var(--secondary-color);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    border-radius: 10px;
    height: auto!important;
}

.write-review-dropzone p {
    background: var(--secondary-color);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    border-radius: 10px;
    padding: 2em;
    min-height: 8em;
    align-items: center;
    justify-content: center;
    border: 1px solid #ced4da;
    font-size: 0.9em;
    text-align: center;
}

.remove-review-image svg {
    max-width: 1.5em;
}

.review-preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #000;
    margin-bottom: 2em;
    border-radius: 10px;
}

.review-preview-img {
    width: 100%;
    height: 15em;
    object-fit: contain;
    border-radius: 10px;
    width: 100%;
}

.remove-review-image {
    position: absolute;
    top: 15px;
    right: 15px;
}

.remove-review-image svg {
    width: 2em;
    fill: #f00;
    cursor: pointer;
}

/* Manage Address CSS */




.manage-address-tab-sec .manage-address-card {
    width: 100%;
    height: 100%;
    color: rgb(63, 63, 63);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: center;
    overflow: hidden;
    transition: all 0.6s;
    padding: 2em;
}

.manage-address-info {
    display: flex;
    justify-content: space-between;
    gap: 1em;
    flex-direction: column;
}



.manage-address-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2em;
    margin-top: 2em;
}

.manage-address-info h4 {
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 700;
    margin: 0;
}

.manage-address-info p {
    color: var(--quaternary-color);
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0;
    max-width: 225px;
    line-height: 1.8;
}

.manage-address-tab-sec .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--primary-color);
}

.manage-address-tab-sec .nav-pills .nav-link {
    background-color: #E1E1E1;
    color: var(--primary-color);
}

.manage-address-tab-sec .nav-pills {
    gap: 1em;
}

.manage-address-action-btn .edit-btn {
    background-color: transparent !important;
    padding: 0 !important;
    border: 0 !important;
}

.manage-address-action-btn .delete-btn {
    background-color: transparent !important;
    padding: 0 !important;
    border: 0 !important;
}

.manage-address-action-btn-sec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.manage-address-action-btn {
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: flex-end;
}

.new-address-form-sec {
    margin-top: 2em;
}

.select-address-type-btn {
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
}

.account-form .height-auto.form-control {
    height: unset;
}

.mr-2 {
    margin-right: 0.5em;
}

.wallet-action-btn-sec {
    display: flex;
    align-items: center;
    gap: 1em;
}

.wallet-header-sec {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2em;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2em 0;
}

.wallet-balance-amount-sec h3 {
    color: var(--tertiary-color);
    font-size: 2em;
    font-weight: 700;
}

.wallet-balance-amount-sec h6 {
    color: var(--tertiary-color);
    font-size: 0.9em;
    font-weight: 400;
}

.wallet-transaction-sec h4 {
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0;
}

.order-list-table table tbody tr td.transaction-success h5 {
    color: #5AC72D !important;
}

.order-list-table table tbody tr td.transaction-failure h5 {
    color: #FF0000 !important;
}

.profile-upload-img-sec img.img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.image-input {
    display: none !important;
}

.profile-upload-img-sec label {
    cursor: pointer;
}

.manage-address-tab-sec .checkbox input {
    opacity: 0;
    position: absolute;
}

.manage-address-tab-sec .checkbox label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 2;
    appearance: none;
    -webkit-appearance: none;
    background: #f5f5f5;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 10px;

}

.manage-address-tab-sec .checkbox label:checked~.manage-address-card {
    background: #F5F5F5;
    border: 1px solid var(--primary-color);
    color: #185ADB;
}

.address-action-btn {
    align-items: center;
    display: flex;
    gap: 1em;
    text-decoration: none !important;
}

.input-edit-img img {
    background: #000;
    border-radius: 50%;
    padding: .4em;
}

.account-form .form-label {
    position: relative;
}

.input-edit-img {
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: 0;
}

.profile-sec .manage-address-tab-sec .manage-address-info{
    gap:0.5em;
}
.profile-header-sec h4 {
    color: var(--tertiary-color);
    font-size: 1.1em;
    font-weight: 600;
  }