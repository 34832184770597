@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-theme: #0E1036;
    --secondary-theme: #FFB52D;
    --tertiary-theme:#005479;
    --primary-text: #252525;
    --primary-color: #404040;
    --white-theme: #fff;
    --color: #00A97F;
    --color2: rgb(10, 25, 30);
}
body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: 0.25s ease;
    transition: 0.25s ease;
    overflow-x: hidden;
    position: relative;
    color: var( --primary-text);
    font-family: 'Red Hat Display', sans-serif!important;
    font-weight: 400;
    font-size: 16px !important;
    letter-spacing: 0.8px;
}
p, h1, h2, h3, h4, h5, h6 {
    color: var(--primary-text);
}

.default-btn-frame {
    display: flex;
    align-items: center;
    gap: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    position: relative;
}

.default-btn-one {
    background-color: var(--secondary-theme) !important;
    color:#01151F!important;
    padding: 0.8em 1em !important;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.5em;
    border: none !important;
    justify-content: center;
}
.default-btn-one:hover {
    background-position: right center;
    background-size: 200% auto;
    -webkit-animation: pulse 2s infinite;
    animation: pulseone 1.5s infinite;
   }
.default-btn-two {
    background-color:#005479 !important;
    color: var(--white-theme) !important;
    padding: 0.8em 1em !important;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.5em;
    border: none !important;
    justify-content: center;
}
.default-btn-two:hover {
    background-position: right center;
    background-size: 200% auto;
    -webkit-animation: pulse 2s infinite;
    animation: anitwo 1.5s infinite;
   }
@keyframes anitwo {
    0% {
     box-shadow: 0 0 0 0 var(--secondary-theme);
    }
   
    70% {
     box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
    }
   
    100% {
     box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
   }
   @keyframes pulseone {
    0% {
     box-shadow: 0 0 0 0 var(--primary-theme);
    }
   
    70% {
     box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
    }
   
    100% {
     box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
    }
   }


.new-section-title h5{
    font-size: 1em;
    font-weight: 500;
    color: var(--quaternary-color);
}
.popular-categories-frame{
    padding-top: 5em;
}
.new-section-title{
    text-align: center;
}

.new-section-title h2{
    font-size: 2em;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 0.5em;
    line-height: 1.4;
    position: relative;
    display: inline-block;
   
}

.new-section-title p{
    color: var(--tertiary-theme);
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.8;
}
.section-img{
    margin: auto;
    margin-bottom: 1em;
}
/* Header section style start  */
.header {
    background-color: var(--primary-theme);
    color: var(--white-theme);
    box-shadow: 0px 4px 11px 0px #0000000F;
}

.moblie-header-section {
    display: none;
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
}

.loaction-icon-head {
    display: flex;
    align-items: center;
    gap: 0.48em;
}

.loaction-icon-head span {
    font-size: 1em;
    color: var(--white-theme);
}

.location-frame {
    cursor: pointer;
}

.form-control:focus {
    box-shadow: none !important;
}

.search-frame-head {
    width: 30%;
}

.search-frame-head span {
    border: 0!important;
}

.card-fav {
    position: relative;
}

.card-fav .card-notifi {
    position: absolute;
    top: 0;
    font-size: 0.7em;
    background-color: red;
    color: var(--white-theme);
    width: 18px;
    height: 18px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-fav-head {
    display: flex;
    align-items: center;
    gap: 1em;
    border-right: 1px solid #ffffff50;
    padding-right: 2em;
}

.auth-frame {
    display: flex;
    align-items: center;
}

.auth-frame a {
    font-size: 1em;
    font-weight: 600;
    color: var(--white-theme);
    display: flex;
    align-items: center;
    gap: 1em;
}

.auth-frame a:hover {
    color: var(--secondary-theme);
} 

.auth-frame a:hover svg path {
    stroke: var(--secondary-theme);
} 

.auth-frame .user-dropdown-sec a:hover{
    color: var(--secondary-theme);
} 

.auth-frame .user-dropdown-sec a:hover svg{
    fill: var(--secondary-theme);
} 

.next-page-tab{
    display: flex;
    gap: 1em;
    padding: 0em 0 1em 0em;
   
}
.next-page-tab span{
    font-size: 0.85em;
    gap: 1em;
    position: relative;
   
}
.next-page-tab a
{
    color: var(--tertiary-theme);
    position: relative;  
    font-weight: 400; 
}
.next-page-tab a.active{
color: var(--primary-text);
display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}
.next-page-tab span::after{
content: '|';
position: absolute;
display: inline-block;
color: var(--tertiary-theme);
right: -10px;
transform: rotate(15deg);
}
.next-page-tab span:last-child::after{
    display: none;
}
/* Header section style end  */
/* Banner section style start  */
.main-hero {
    background-image: url('/public/assets/img/new/home-banner.png');
    min-height: calc(100vh - 120px);
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.banner-content {
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
  /* justify-content: flex-end; */
  justify-content: center;
  position: relative;
  z-index: 999999999;
}
.banner-content h1{
    color: var(--white-theme);
    font-size: 3em;
    font-weight: 700;
}
.banner-content span{
    font-family: 'Dancing Script', cursive;
    font-size: 2em;
}
.banner-content p{
    max-width: 499px;
    color: var(--white-theme);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.8;
}
/* .main-hero::after{
    content: '';
    background-color: #ffffff1f;
    position: absolute;
    width: 40%;
    inset: 0;
} */
/* Banner section style start  */

/* Things secction start  */
.thing-product-main{
    display: flex;
    text-align: center;
    padding-top: 1em;
    gap: 3em;
    row-gap: 2em;
    align-items: start;
    flex-wrap: wrap;
    justify-content: center;
    overflow: auto;
    padding-bottom: 2em;
}
.thing-product-main::-webkit-scrollbar {
    /* width: 5px; */
  height: 8px;
  background-color: transparent;
  }
  .thing-product-main::-webkit-scrollbar-thumb {
    background: #000;
     width: 5px;
     max-width: 500px;
  }
.thing-info p,.popular-card-info p{
    margin-top: 0.5em;
    font-size: 1.2em;
}

.thing-info h4{
    color: var(--primary-text);
    font-size: 0.98em;
    font-weight: 500;
    line-height: 1.8;
    margin-bottom: 0;
}
.thing-image-sec{
    border: 1px solid #efefef;
    border-radius: 50%;
    overflow: hidden;
    height: 6em;
    width: 6em;
}
.thing-image{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
/* Things secction end  */

/* popular-Categories section  */
.popular-card-img img{
    width: 100%;
    height: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 2s ease-out;
    transition: all 2s ease-out !important;
}
.popular-card-img {
    /* background-color:  #F6F6F6; */
    width: 8em;
    height: 8em;
    object-fit: contain;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    border: 1px solid #efefef;

}
.popular-categories-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}
.popular-categories-card:hover .popular-card-img img{
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.popular-categories-main{
    display:flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}
/* popular-Categories section  */
/* trending-product- section  */
.trending-product-frame{
    padding: 6em 0;
}
/* .trending-product-tabs-frame{
    padding-top: 2em;
} */
.trending-product-tabs-frame ul{
    justify-content: center;
    border: none;
}
.trending-product-tabs-frame  .nav-tabs .nav-link.active{
    border: none;
    color: var(--tertiary-theme);
    position: relative;
}
.trending-product-tabs-frame  .nav-tabs .nav-link.active::after{
    content: ' ';
    position: absolute;
    background: var(--secondary-theme);
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
}
.trending-product-tabs-frame  .nav-tabs .nav-link{
    border: none;
    color: var(--primary-text);
    font-weight: 500;
    font-size: 1.1em;
}
/* Trending Products start  */
.tending-product-card-frame {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    gap: 2em;
}
.product-card-img-overlay{
    /* position: relative; */
    /* overflow: hidden; */
}
.trending-product-card:hover .product-overlay{
    visibility: visible;
    opacity: 1;
}
.trending-product-card{
    margin-top: 2em;
    position: relative;
}
.product-card-img img {
    width: 100%;
    height: 15em;
    transform: scale(1);
    transition: all 2s ease-out !important;
    object-fit: contain;
}

.trending-product-item{
    cursor: pointer;
}
.product-card-img{
    position: relative;
    height: 15em;
    width: 100%;
    overflow: hidden;
    object-fit: contain;
    border-radius: 8px;
    border: 1px solid #efefef;
}
 .product-overlay-left{
    position: absolute;
    left: 10px;
    top: 10px;
}
.product-overlay-left span{
    /* background-color: red; */
    color: var(--white-theme);
    position: relative;
    z-index: 99;
    width: 6em;
    font-size: 0.8em;
    font-weight: 400;
    display: inline-block;
    text-align: center;
}
.product-preview-conatiner .react-slider__description{
    display: none!important;
}
.product-overlay-left span::after{
    /* new/discount-rib.png */
    content: ' ';
    position:absolute;
    background-image: url("/public/assets/img/new/discount-rib.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    inset: 0;
    width: auto;
    z-index: -1;
    height: 100%;
    display: inline-block;


}
.product-overlay-right{
    /* float: right; */
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
}
.product-card-info{
    padding-top: 1em;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.product-card-info h5{
    color:#5D5D5D;
    font-size: 0.85em;
    font-weight: 500;
    cursor: pointer;
}
.product-card-info p{
    color:var(--primary-text);
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0.5em;
}
.product-rate {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    text-align: left;
}
.product-rate span{
    color:#005479;
    font-size: 0.9em;
    font-weight: 500;
}

.dis-rate{
    text-decoration: line-through;
    color: #5d5d5d!important;
}
.review-product-details-info .card-fav-icon{
    float: right;
}
.card-fav-icon ,.card-cart-icon{
    border: 1px solid var(--tertiary-theme);
    background-color:  #fff;
    border-radius: 4px;
    height: 1.8em;
    width: 1.8em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.card-fav-icon.active{
    background-color:var(--tertiary-theme);
}
.card-fav-icon:hover,.card-cart-icon:hover{
    background-color: var(--tertiary-theme);
}
.trending-product-card:hover .product-overlay .product-overlay-right{
    transform: translateX(0px);
}
.trending-product-card:hover .product-card-img img{
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.trending-product-card:hover .product-card-info h5, .trending-product-card:hover .product-card-info p{
    color: var(--tertiary-theme);
}
/* Trending Products end  */
.ctn-banner-frame{
    width: 100%;
    overflow: hidden;
}
.ctn-banner-frame img{
    width: 100%;
-webkit-animation: scale 20s linear infinite;
animation: scale 20s linear infinite;
}
@keyframes scale{
0% {
    transform: scale(1.1);
}
20% {
    transform: scale(1.05);
}

40% {
    transform: scale(1);
}
60% {
    transform: scale(1);
}
80% {
    transform: scale(1.05);
}
100% {
    transform: scale(1.1);
}
}

/* special product start  */
.special-product-frame{
    padding: 6em 0;
}
.popular-card-info h5{
    color:#5D5D5D;
    font-size: .8em;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;

}
.popular-card-info{
    padding-top: 1em;
    text-align: center;
}
.popular-card-info span{
    color:var(--tertiary-theme);
    font-size: 1em;
    font-weight: 500;
}
.special-product-main-frame{
    display: grid;
    grid-template-columns: repeat( 6, 1fr);
    gap: 1em;
}
/* special product start  */
.flow-part-card{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white-theme);
    padding: 2em;
}
.flow-part-frame{
    padding: 4em 2em;
    background: #F4FDFF;
}
.flow-part-info{
    padding-top: 1em;
}
.flow-part-info h4{
    color: var(--primary-text);
    font-size: 1em;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0.5em;
}

.flow-part-info p{
    color: var(--primary-text);
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1.5;
}


/* Customer start  */
.customer-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 1em;
}
.customer-card-img{
    width: 5em;
    height: 5em;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid var(--secondary-theme);
}
.customer-card-info{
    text-align: center;
}
.customer-card-info h4{
    font-weight: 600;
    font-size: 1.2em;
    color: var(--tertiary-theme);
}
.customer-card-info p{
    color: var(--primary-text);
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 1em;
    line-height: 1.5;
}
.customer-quotes{
    position: absolute;
    left: 0;
    top: 25%;
}
.customer-card-info{
    padding-top: 1em;
}

.customer-frame{
    padding: 0px;
}
.customer-slide-frame{
    padding-top: 2em;
    padding-bottom: 2em;
}
.customer-slide-frame .slick-slider{
    margin-top: -5em;
}
.subscribe-input-frame{
    width: 599px;
    margin: auto;
}
.subscribe-input-frame {
    padding-top: 2em;
    padding-bottom: 6em;
}
.subscribe-input-frame span{
    padding: 0!important;
}
.subscribe-input-frame span button{
 border-radius: unset!important;

}
.subscribe-input-frame .form-control:focus{
    background-color:#F6F6F6!important;
}
.subscribe-input-frame .form-control{
    background-color:#F6F6F6;
    border-radius: 0;
    border: none;
    font-size: 1.1em;
}
.subscribe-input-frame .form-control::placeholder{
    font-size: 0.9em;
}
.shipping-flow-frame{
    background: #F3FBFF;
    padding: 4em 0;
}
.shipping-flow-img{
    background-color: #fff;
    background-color: #fff;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shipping-flow-info{
    padding-top: 1em;
}
.shipping-flow-info h4{
    font-weight: 600;
    font-size: 1.2em;
    color: var(--tertiary-theme);
    margin-bottom: 0.8em;
}
.shipping-flow-info p{
    color: var(--primary-text);
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0em;
    line-height: 1.5;
}
/* footer  */
.new-footer-sec{
    background-color:#052D3F;
    padding: 4em 0;
}
.footer-quick-link h5 ,.footer-info-link h5,.footer-app-link h5,.footer-media-link h5,.footer-accept-link h5,.popular-link h5{
    font-size: 1.2em;
    font-weight: 600;
    color: var(--white-theme);
    margin-bottom: 1em;
}
.footer-quick-link ul li a,.footer-info-link ul li a{
    font-size: 0.95em;
    color: var(--white-theme);
    font-weight: 500;
    line-height: 1.8;
}

.footer-quick-link ul li, .footer-info-link ul li{
    padding-bottom: 0.6em;
}

.footer-quick-link ul li:last-child, .footer-info-link ul li:last-child{
    padding-bottom: 0;
}

.footer-quick-link ul li a:hover,.footer-info-link ul li a:hover{
    color: var(--secondary-theme);
}
.footer-app-link ul{
    display: flex;
    gap: 1em;
}
.footer-app-link ul li img{
    width: 7em;
    height: 3em;
    object-fit: contain;
}
.footer-media-link ul {
   display: flex;
   align-items: center;
   gap: 1em;
   margin-bottom: 0;
}
.footer-media-link ul li a img{
   
    object-fit: contain;
}
.footer-media-link ul li a {
    background-color: #fff;
    width: 2em;
    height: 2em;
    padding: 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.footer-accept-link ul li a{
 border: 1px solid #efefef17;
 border-radius: 8px;
 padding: 0.5em;
 display: inline-block;
 background-color: #F6F6F6;
}
.footer-accept-link ul li a img {
    width: 4em;
    height: 2em;
    object-fit: contain;
}
.footer-accept-link .footer-accept-link-list{
    display: flex;
    align-items: center;
    gap: 0.3em;
}
.footer-accept-link{
    padding: 1em 0;
}
.popular-link  ul{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1em;
}
.popular-link  ul li{
    position: relative;
}
.popular-link  ul li::after{
    content: '|';
    position: absolute;
    display: inline-block;
    z-index: 99;
  color: #fff;
  right: -10px;
}
.popular-link  ul li:last-child::after{
display: none;
}
.popular-link  ul li a{
    font-size: 0.95em;
    color: var(--white-theme);
    font-weight: 500;
    line-height: 1.8;
    position: relative;
}
.footer-sec{
    display: none;
}
.footer-contact p{
    font-size: 1em;
    color: var(--white-theme);
    margin-bottom: 0em;
    font-weight: 600;
}
.footer-contact p a{
    color: var(--secondary-theme);
    text-decoration: underline!important;
}
.foot-last{
    text-align: center;
    background-color: var(--tertiary-theme);
    color: var(--white-theme);
    padding: 1em 0;
}
.foot-last p{
    font-size: .9em;
    color: var(--white-theme);
    margin-bottom: 0em;
    font-weight: 500;
}
.product-preview-conatiner .next-page-tab{
    display: none;
}
.product-full-details-frame{
    padding: 0 2em;
}
.responsive-filetr{
    display: none;
}
.header-sub-scroll-bar{
    padding: 1em 0;
    box-shadow: 0px 1px 4px 0px #A2A2B73D;
}

.nav-scroll-slide .slick-prev:before, .nav-scroll-slide .slick-next:before {
    font-size: 30px!important;
    font-weight: 700;
    line-height: 0.8;
}
.nav-scroll-slide .slick-initialized .slick-slide{
text-align: center;
}
.nav-scroll-slide .slick-next:before, .nav-scroll-slide .slick-prev:before {
    color: var(--primary-color) !important;
}
.nav-scroll-slide a{
    color: var(--primary-text);
    font-size: 0.95em;
    font-weight: 600;
}
.login-link-sec-1{
    display: flex;
    gap: 1em;
}

/* .product-preview-conatiner{

    position: -webkit-sticky !important;
    position: sticky !important;
    height: auto;
    top: 2em;
} */
.infinite-scroll-component .section-title{
    margin-bottom: 0em!important;
    margin-top: 1em;
}
/* .nav-scroll-slide .slick-next {
    right: 10px;
}

.nav-scroll-slide .slick-prev {
    left: -5px;
} */

@media (max-width: 767.98px) {
    .address-details-wrap{
        flex-direction: column;
    }
    .address-full-details{
        width: 100%;
        padding-bottom: 1em;
        border-bottom: 1px solid #efefef;
    }
    .address-details-head{
        width: 100%;
        padding-top: 1em;
    }
    .arriving-track-grape ul li .grap-tick::after {
        min-width: 2px!important;
        height: 50px!important;
        left: 12px!important;
        top: 100px!important;
    }
    .tracking-section {
        flex-direction: column;
        width: 100%;
    }
    .track-header {
        flex-direction: column;
    }
    .search-order-history-sec{
        width: 100%;
    }
    .order-history-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .product-details-price-frame {
        padding: 1em!important;
    }
    .login-link-sec-1 svg{
        width: 90%;
    }
    .arriving-track-grape ul {
        flex-direction: column!important;
        gap: 6em;
    }
    .arriving-track-grape ul li{
        /* margin-bottom: 3em; */
        flex-direction: column-reverse!important;
    }
    .bottom-grap-title {
        bottom: -20px!important;
    }
    .arriving-status-wrapped h3{
        text-align: center;
    }
    .orders-history-card-img {
        width: 100%!important;
        margin-bottom: 1em;
    }
    .responsive-filetr{
        display: block!important;
        text-align: right;
    }
    .responsive-filetr a{
        font-size: 1.2em;
        font-weight: 600;
        color: var(--tertiary-theme);
    }
    .desk-filetr{
        display: none;
    }
    .history-status-wrapped-right {
        flex-direction: column;
    }
    .history-status-wrapped-right span::before {
       display: none!important;
    }
    .orders-history-card {
        grid-template-columns: auto!important;
    }
    .search-order-history-sec .input-group {
        min-width: 100%!important;
    }
    .order-details-wrapped {
        flex-direction: column;
        padding: 0!important;
    }
    .rating-product-review-frame {
        flex-direction: column;
        gap: 1em;
    }
    .product-details-variety-card {
        flex-direction: column!important;
    }
    .product-full-details-frame {
        padding: 0 0em!important;
    }
    .material-type-frame a,.material-size-frame a{
        margin-bottom: 0.5em!important;
        text-align: center !important; 
    }
    .product-preview-conatiner .next-page-tab {
        display: flex!important;
        margin-bottom: 1em;
    }
    .header-section {
        display: none;
    }
    .banner-content h1 {
        font-size: 2em!important;
        text-align: center;
    }
    .moblie-header-section {
        display: block !important;
    }

    .header-sub-frame {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 1em 0;
    }

    .search-frame-head {
        width: 100%;
        display: grid;
        grid-template-columns: 20em auto;
        align-items: center;
        justify-content: space-around;
    }

    .header-location-moblie {
        margin-top: 1em;
        background-color: #FFB52D;
        padding: .3em 0;
    }

    .loaction-icon-head {
        justify-content: center;
    }
    .banner-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        z-index: 1050;
    }
    .new-section-title h2{
        font-size: 1.5em!important;
    }
    .new-section-title p {
        font-size: 0.9em;
    }
    .main-hero {
        background-position: 100% 0%!important;
    }
    .main-hero::after{
    content: '';
    background-color: #00547973;
    position: absolute;
    width: 100%;
    inset: 0;
    z-index: 0;
}
    .ctn-banner-frame {
        height: 100%!important;
    }
    .special-product-frame {
        padding: 2em 0!important;
    }
    .customer-frame {
        padding: 2em 0!important;
    }
    footer {
        padding: 2em 0!important;
    }
    .banner-content p {
        text-align: center;
    }
    .default-btn-frame{
        justify-content: center;
    }
    /* thing- section  */
    .thing-product-main {
        display: grid!important;
        justify-content: space-between;
        text-align: center;
        padding: 2em 0!important;
        grid-template-columns: repeat(2, 1fr)!important;
        gap: 0em!important;
        row-gap: 2em!important;
    }
    .popular-categories-main{
        gap: 1em;
        display: grid!important;
        justify-content: space-between;
        text-align: center;
        padding: 2em 0!important;
        grid-template-columns: repeat(2, 1fr)!important;
    }
    .tending-product-card-frame {
        grid-template-columns: repeat(1, 1fr)!important;
    }
    .trending-product-frame {
        padding: 2em 0!important;
    }
    .special-product-main-frame {
        grid-template-columns: repeat( 2, 1fr)!important;
    }
    .subscribe-input-frame {
        width: 100%!important;
    }
    .customer-frame .new-section-title h2::before,.subscribe-start-frame .new-section-title h2::before{
     display: none!important;
    }
    .rating-product-review-frame {
        grid-template-columns: unset!important;
    }
    .customer-frame .new-section-title h2::after,.subscribe-start-frame .new-section-title h2::after{
        display: none!important;
    }
    .review-product-details {
        grid-template-columns: auto!important;
    }
    .review-product-details-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .review-product-details-img {
        width: 100%!important;
        margin-bottom: 1em;
        height: 100%!important;
    }
    .review-product-details-info .smilar-fav-icon {
        float: unset!important;
    }
    .order-details-filter{
        height: 100%;
        width: 100%!important;
        float: left;
    }
    .order-history-details{
        width: 100%!important;
        float: left;
        padding: 1em!important;
    }
    .order-history-head, .history-status-wrapped {
        align-items: flex-start!important;
        flex-direction: column;
        gap: 1em!important;
    }
    .orders-history-card-info   .order-history-head {
        gap: 0em!important;
    }
    .delivery-main-frame {
        align-items: flex-start!important;
        flex-direction: column!important;
    }
    .delivery-left-wrap{
        text-align:left;
        width: 100%;
    }
    .next-page-tab {
        gap: 0 1em!important;
        flex-wrap: wrap!important;
    }
    .product-full-details-frame .next-page-tab{
        display: none;
    }
    
        /* thing- end  */
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .banner-content {
        min-height: calc(100vh - 740px)!important;
    }
    .banner-content h1 {
        font-size: 2em!important;
    }
    .fav-frame svg,.card-fav img,.auth-frame svg{
        width: 2em;
        height: 1.5em;
    }
    .thing-product-main {
        display: grid!important;
        justify-content: space-between;
        text-align: center;
        padding: 3em 0;
        grid-template-columns: repeat(5 ,1fr)!important;
    }
    .popular-categories-main{
        gap: 1em;
        display: grid!important;
        justify-content: space-between;
        text-align: center;
        padding: 2em 0!important;
        grid-template-columns: repeat(4, 1fr)!important;
    }
    .special-product-main-frame {
        grid-template-columns: repeat( 3, 1fr)!important;
    }

.tending-product-card-frame {
    grid-template-columns: repeat(2, 1fr)!important;
}
.arriving-track-grape ul li .grap-tick::after {
    width: 84px!important;
    left: 40px!important;
}
.history-status-wrapped {
    flex-direction: column;
    align-items: flex-start!important;
}
.responsive-filetr {
    display: block!important;
    text-align: right;
}
.desk-filetr {
    display: none;
}
.order-details-filter {
    width: 100%!important;
    float: left;
}
.responsive-filetr a {
    font-size: 1.2em;
    font-weight: 600;
    color: var(--tertiary-theme);
}
.order-details-wrapped {
    flex-direction: column;
}
.order-history-details {
    width:100%!important;
}
}


.search-frame-head .form-control:focus{
    box-shadow: none!important;
}

.search-frame-head .form-control{
    height: 45px;
    border: 0!important;
}

.popular-card-info h4 {
    color: var(--primary-text);
    font-size: 0.8em;
    font-weight: 500;
    line-height: 1.8;
    margin-bottom: 0;
    white-space: nowrap;
}

.new-client-sec{
    background-color: #FFB52D0F;
    margin-top: 6em;
}

.footer-accept-icon{
    height: 2em;
    object-fit: contain;
}
.next-page-tabs{
    display: flex;
    gap: 1em;
    padding: 0em 0 1em 0em;
   
}
.slide-wrapped img{
    min-height: calc(100vh - 200px);
    width: 100%;
    object-fit: cover;
    border-radius: 30px;
    animation: radiusAnimation 5s ease-in-out infinite;
    height: 546px;
    }
.demo-swiper-one{
position: relative;
background-color: var(--primary-theme);
/* background: linear-gradient(45deg, #ffb52d, #005479); */
padding: 2em 0;
/* background: url("/public/assets/img/new/dddepth-183.jpg"); */
}




/* .hero-mySwiper.swiper-horizontal > .swiper-pagination-bullets,
.hero-mySwiper .swiper-pagination-bullets.swiper-pagination-horizontal {
bottom: 0!important;
top: auto!important;
left: -100px!important;
}
.swiper {
overflow:unset!important;
overflow: unset!important;
}
.hero-mySwiper .swiper-pagination{
text-align: left!important;
} */
.hero-mySwiper .swiper-button-prev, .hero-mySwiper .swiper-button-next{
    display: none!important;
}
.hero-mySwiper .swiper-pagination-bullet {
width: 30px!important;
height: 4px!important;
display: inline-block;
border-radius: 4px!important;
background: #fff!important;
}
.hero-mySwiper .swiper-pagination-bullet-active {
background:var(--secondary-theme) !important;
}
@keyframes radiusAnimation {
0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
}
50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
}
100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
}

}
.thing-frame-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
