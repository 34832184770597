.verification-box {
    /* background: #F4F4F4; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2em;
    margin-top: 2em;
}

.verification-box h4 {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 0.5em;
    color: var(--teritary-color);
    line-height: 1.5;
}

.verification-box p {
    color: var(--teritary-color);
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 0;
}

.verification-item {
    margin: 2em 0;
}

.verification-item input {
    width: 3rem !important;
    height: 3rem;
    font-size: 1.8rem;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.verification-btn-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .verification-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
} */

.verification-logo{
    height: 2em;
    object-fit: contain;
    margin: auto;
}

.verification-logo-sec{
    text-align: center;
}