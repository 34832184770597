.login-modal .modal-close{
    background-color: transparent!important;
    padding: 0!important;
    border: 0!important;
    position: absolute;
    right:5px;
    top: 5px;
    z-index: 9;
  }
  
  .login-modal .modal-close .close-icon{
    width:2em;
  }
  
  .login-modal .modal-close:focus{
    box-shadow: none !important;
    outline:none !important;
  }
  
  .login-modal .modal-dialog{
    max-width: 600px;
    border-radius: 10px;
  }
  
  .login-modal .modal-content{
    border-radius: 0px!important;
  }
  
  .login-box{
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2em;
  }

  .login-header-title-sec{
    position: absolute;
    top: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 38%;
    background: var(--secondary-color);
  }

  .login-modal .modal-body{
    padding: 2.5em;
  }

  .login-header-title-sec h3{
    padding: 0 1em;
    text-align: center;
    font-family: 'Cormorant', serif;
  }

  .auth-form .form-control{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)!important;
    height: 45px;
    border: 0!important;
  }

  .auth-form .form-control:focus{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)!important;
  }

  .auth-form .input-group-text{
    border: 0!important;
    background-color: transparent;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)!important;
    background-color: var(--secondary-color)!important;
  }

  .auth-form .form-label{
    font-size: 0.8em;
    font-weight: 600;
    color: var(--tertiary-color);
    text-transform: uppercase;
  }

  .lost-password-link-sec a{
    color: #203C3D;
    text-decoration: underline!important;
    font-size: 0.8em;
    font-weight: 500;
  }

  .lost-password-link-sec a:hover{
    color: #203C3D;
  }

  .auth-btn-sec{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1em;
  }
  