.review-product-main-wrap,.product-details-frame{
    padding: 2em 0;
}

.product-details-price-frame{
    background-color: #F4FDFF;
    border-radius: 12px;
    padding: 2em;
}
.product-preview-conatiner .react-slider__ul{
   padding-left: 0!important;
}
.product-details-price-frame h3{
    font-size: 1.5em;
    font-weight: 600;
}
.product-details-price-frame h5{
color: var(--tertiary-theme);
font-size: 1em;
}
.singale-product-rate{
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: .5em;
}
.singale-product-rate .current-rate{
    font-size: 1.2em;
    font-weight: 600;
    color: var(--primary-text);
}
.singale-product-rate .net-rate{
    font-size: 1em;
    font-weight: 500;
    color: var(--secondary-text);
    text-decoration: line-through;
}
.singale-product-rate .discount-per{
    font-size: 0.8em;
    font-weight: 500;
    color: #27AE60;  
}
.product-details-price-frame .product-stock-text{
    font-size: 0.8em;
    font-weight: 500;
    color: #27AE60;  
    margin-bottom: 0em;
}
.material-btn-box{
    margin-bottom: 1em;
}
.product-details-price-frame .product-stock-text span{
    color: var(--secondary-text)!important;
}
.material-type-frame {
    margin-top: 1em;
}
.material-type-frame a,.material-size-frame a{
    border: 1px solid #EAEAEF;
    padding: 0.5em 1.5em;
    display: inline-block;
    border-radius: 30px;
    margin-right: 1em;
    color: var(--primary-text);
    margin-bottom: 1em;
    font-size: 0.9em;
    flex: 1 1;
    white-space: nowrap;
}
.material-size-frame ul{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
}
.material-type-frame a.active,.material-type-frame a:hover,.material-size-frame a.active,.material-size-frame a:hover{
    border: 1px solid #005479;
    color: var(--tertiary-theme);
    background: #F4FDFF;
}
.material-type-frame h4,.material-size-frame h4,.product-details-variety-frame h4,.product-specification-frame h4,.product-description-frame h4,.product-review-head h4{
font-size: 1.2em;
font-weight: 600;
margin-bottom: 1em;
}
.product-details-variety-frame h4{
    margin-bottom: 0.5em;
}
.material-size-frame{
    padding: 1.5em 0;
    padding-top: 0.5em;
}
.material-size-frame a{
    margin-bottom: 1em;
}
.product-details-variety-frame span{
    font-size: 0.9em;
    color: var(--secondary-text);
    display: grid;
    align-items: start;
    gap: 0.5em;
    grid-template-columns: 22px auto;
}
.product-details-one{
    border-radius: 20px;
background: rgba(255, 195, 0, 0.08);
padding: 1.5em;
width: 100%;
height: auto;
}
.product-details-two{
    border-radius: 20px;
background: #F3FBFF;
padding: 1.5em;
width: 100%;
height: auto;
}
.product-details-variety-card{
    display: flex;
    align-items: center;
    padding: 1.5em 0;
    gap: 2em;
}
.product-details-variety-card .product-details-card-info h5{
    font-size: 1em;
    color: #737373;
    margin-bottom: 0.5em;
}
.product-details-variety-card .product-details-card-info p{
    font-size: 1em;
font-weight: 600;
margin-bottom: 0;
}
.product-details-card-img{
    padding-bottom: 1em;
}
.specification-card span:nth-child(1) {
    border-radius: 50px;
    background: rgba(255, 195, 0, 0.08);
    padding: .5em 2em;
    height: auto;
    display: inline-block;
    text-align: center;
    color: var(--tertiary-theme);
    background: #F4FDFF;
   flex: 1 1;
}
.specification-card span:nth-child(2){
    padding: .5em 2em;
    /* width: 100%; */
    height: auto;
    display: inline-block;
    text-align: center;
}
.specification-card{
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    gap:1em;
}
.product-specification-frame,.product-description-frame ,.product-review-frame{
    border: 1px solid #EAEAEF;
    border-radius: 20px;
    padding: 1.5em;
}
.product-description-frame{
    margin: 1.5em 0;
}
.product-review-head a{
    color: var(--tertiary-theme);
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.product-review-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product-review-head h4{
    margin-bottom: 0;
}
.product-rating-point h2{
    color: #FFC300;
    font-size: 2.5em;
    font-weight: 700;
    position: relative;
    padding: .2em 0;
}
.product-rating-point h2 span{
    position: relative;
}
.product-rating-point h2 span svg{
    position: absolute;
    top: 20%;
   }
   .product-rating-point p{
    color: var(--primary-text);
    font-weight: 600;
    font-size: 0.9em;
   }
   .review-preview-card-img{
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    overflow: hidden;
   }
   .review-preview-card-img img{
    width: 100%;
    object-fit: cover;
    height: 100%;
   }
   .review-preview-card-info{
    display: flex;
    align-items: center;
    gap: 1em;
   }
   .review-preview-frame{
    border-top: 1px solid  #EAEAEF;
    padding: 1.5em 0;
   }
   .review-preview-card{
    display: flex;
    align-items: center;
    gap: 1em;
   }
   .review-preview-card-info h3{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--tertiary-theme);
   }
   .review-preview-card-info span{
    font-size: 0.85em;
    color: #757575;
    font-weight: 500;
    opacity: 0.7;

   }
   .product-description-frame p{
    line-height: 1.8;
    font-size: 1em;
    margin-bottom: 0;
   }
.review-preview-ratings{
    display: flex;
}
.review-preview-ratings .rating-notifi{
    margin-left: 1em;
    font-weight: 600;
    color: var(--tertiary-theme);
}
.rating-product-review-frame{
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.rating-product-review-frame p:nth-child(1){
    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all; */
    flex: 1 1;
    /* font-size: 1em; */
    line-height: 1.6;
    margin-bottom: 0;
}
.rating-product-review-frame p:nth-child(2){
    display: flex;
    color: var(--tertiary-theme);
    cursor: pointer;
    margin-bottom: 0;
    gap: .5em;
}

.rating-product-review-frame p{
    font-size: 0.9em;
    font-weight: 400;
    color: var(--secondary-text);
}
.review-preview-ratings{
    padding: 1em 0;
}
.view-more-section a{
    color: var(--tertiary-theme);
    font-weight: 700;
    font-size: 0.9em;
}
.smilar-fav-frame{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    margin-top: 0.8em;
}
.similar-product-frame{
    padding:4em 0;
}
.similar-product-frame .new-section-title
{
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.smilar-fav-frame p{
    display: flex;
    align-items: center;
    gap: .5em;
    border: 1px solid #EAEAEF;
    padding: 0.5em 1.5em;
    border-radius: 30px;
    color: var(--primary-text);
    font-size: .9em;
    font-weight: 600;
    margin-bottom: 0em;
}
.smilar-fav-frame .smilar-fav-icon.active ,.review-product-details-info .smilar-fav-icon.active {
    background: #FFF3F3;
    padding: 0.5em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 2em;
    height: 2em;
    float: right;
}
.smilar-fav-frame .smilar-fav-icon,.review-product-details-info .smilar-fav-icon {
    background: #F4FDFF;
    padding: 0.5em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 2em;
    height: 2em;
    font-size: 0.9em;
    float: right;
}
.smial-product-info h3{
    font-size: 1.2em;
    font-weight: 600;
}
.smial-product-info h3 span{
    font-size: 1em;
    font-weight: 600;
    color: var(--secondary-text);
    text-decoration:line-through;
}
.similar-product-text{
    font-size: .8em;
    font-weight: 500;
    line-height:1.8;
    margin-bottom: 0.5em;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.smial-product-info span{
    color: var(--primary-theme);
    font-size: 0.9em;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}
.smial-product-info{
    padding:1em 0;
}
.smial-product-img img{
    width: 100%;
    height: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 2s ease-out;
    transition: all 2s ease-out !important;
}
.smial-product-img{
    background-color: #F6F6F6;;
    position: relative;
    height: 15em;
    object-fit: contain;
    width:100%;
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid #efefef   ;
}
/* .smilar-product-card{
    width: 15em;
    height: 100%;
} */
.similar-product-frame .slick-slide{
    padding-left: 1.5em;
}
.smilar-product-card:hover .smial-product-img img{
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.similar-product-frame .slick-prev:before,.similar-product-frame .slick-next:before {
    font-size: 40px!important;
    font-weight: 700;
    color: #315779!important;
}



/* Review page  */
.reviews-bar-card .progress {
    height: 10px;
}
.reviews-bar-wrap ul li{
    margin-bottom: 1em;
}
.reviews-bar-card span{
    color: var(--primary-text);
    font-weight: 500;
}
.five-start .progress-bar  {
    background: #27AE60!important;
}
.four-start .progress-bar  {
    background: #27AE60!important;
}
.three-start .progress-bar  {
    background: #FFB52D
    !important;
}
.two-start .progress-bar  {
    background: #F2994A!important;
}
.one-start .progress-bar  {
    background: #FA3434!important;
}
.reviews-bar-card{
    display: flex;
    align-items: center;
    gap: 1em;
}
.reviews-bar-card span:nth-child(1){
    width:15%;
}
.reviews-bar-card span:nth-child(2){
    width: 100%;
}
/* .reviews-bar-card span:nth-child(3){
    width:10%;
} */
.review-product-details-img{
    width: 10em;
    height: 10em;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #EAEAEF;
}
.review-product-details-img img{
    width: 100%;
    height: 100%;
}
.delivery-main-frame{
    text-align: center;
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: space-between;
}
.delivery-right-wrap{
    text-align: center;
    display: flex;
    align-items: center;
    gap: 1em;
}
.delivery-left-wrap p{
   margin-bottom: 0;
}
.delivery-left-wrap a{
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-weight: 500;
    color: var(--tertiary-theme);
}
.product-preview-conatiner  .default-btn-one, .product-preview-conatiner  .default-btn-two {
    width: 100%;
}
.delivery-main-frame .delivery-icon{
    background: #F4FDFF;
    /* padding: 1em; */
    border-radius: 50%;
    width: 3em;
    height: 3em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.review-product-details-info p{
    margin-bottom: 0;
}
.review-product-details-info p a{
    font-size: 0.9em;
    font-weight: 600;
    color: var(--primary-text);
}
.delivery-main-frame  .delivery-info h5{
    font-size: 0.9em;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0.1em;
}
.delivery-main-frame  .delivery-info span{
color: var(--secondary-text);
font-size: .8em;
    line-height: 1.5;
    font-weight: 500;
}
.review-product-details{
    display: grid;
    grid-template-columns: 180px auto;
    align-items: center;
    border: 1px solid #EAEAEF;
    padding:1em;
    border-radius: 16px;
    margin: 1em 0;
}
.review-product-details-info .product-name {
    color: var(--tertiary-theme);
    font-weight: 500;
    padding: 0.5em 0;
    display: inline-block;
}
.review-no-product{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5em 0;
}
.review-no-product-info{
    text-align: center;
}
.review-no-product-img{
    width: 18em;
    height: 18em;
}
.review-no-product-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.review-no-product-info h4{
    font-size: 1.2em;
font-weight: 600;
margin-bottom: 1em;
}
.review-no-product-info p{
    line-height: 1.5;
}
.review-product-details-info h4{
    margin-bottom: 0em;
    flex: 1 1;
}
.review-product-details-info h4 a{
    font-size: 0.9em;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    flex: 1 ;
    color: var(--primary-text);
}

.review-product-details-head{
    display: flex;
    align-items: center;
    gap: 2em;
    justify-content: space-between;
}

.fav-frame{
    position: relative;
}

.add-fav-count{
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: var(--primary-theme);
    font-size: 12px;
    font-weight: 600;
    display: flex!important;
    justify-content: center;
    align-items: center;
    top: -10px;
    left: 12px;
    color: #f7b543;
    z-index: 1;
    border: 1.5px solid #fff;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.auth-frame a:hover .add-fav-count{
    border-color: #f7b543;
}