.add-money-header-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add-money-amount-details {
    display: flex;
    align-items: center;
    gap: 1em;
}

.add-money-amount-details .form-control {
    min-width: 350px;
    height: 45px;
}

.add-money-amount-details .form-control:focus {
    box-shadow: none;
}

 .Input:focus *, .p-Input--focused *{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
}

.add-money-card-select-sec .checkbox input {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 2;
    appearance: none;
    -webkit-appearance: none;
}

.add-money-card-select-sec .manage-address-card {
    width: 100%;
    height: 100%;
    color: rgb(63, 63, 63);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: center;
    overflow: hidden;
    transition: all 0.6s;
    padding: 2em;
}

.add-money-card-select-sec .checkbox input:checked~.manage-address-card {
    background: #F5F5F5;
    border: 1px solid var(--primary-color);
    color: #185ADB;
}

.manage-address-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2em;
    margin-top: 2em;
}

/* .manage-address-info h4 {
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 700;
    margin: 0;
}

.manage-address-info p {
    color: var(--quaternary-color);
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0;
    max-width: 225px;
} */

.add-money-card-select-sec .manage-address-card h5 {
    font-size: 1em;
}

.edit-dlete-action-btn {
    display: flex;
    align-items: center;
    gap: 1em;
}

.add-new-card-btn-sec {
    margin-top: 2em;
}

.add-card-box {
    margin-top: 2em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2em;
}

.checkbox {
    position: relative;
}

/* Withdraw CSS */

.manage-billing-tab-sec.withdraw-tab-sec {
    margin-top: 2em;
}



.manage-billing-tab-sec .manage-billing-card {
    width: 100%;
    height: 100%;
    color: rgb(63, 63, 63);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: center;
    overflow: hidden;
    transition: all 0.6s;
    padding: 2em;
}

.manage-billing-info {
    display: flex;
    justify-content: space-between;
    gap: 1em;
    flex-direction: column;
}

.check-box-tick-icon {
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    position: absolute;
    top: 20px;
    right: 20px;
}

.manage-billing-tab-sec .checkbox input:checked~.manage-billing-card .check-box-tick-icon {
    opacity: 1;
    visibility: visible;
}

.manage-billing-tab-sec .checkbox input:checked~.manage-billing-card {
    background: #F5F5F5;
    border: 1px solid var(--primary-color);
    color: #185ADB;
}

.manage-billing-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    margin-top: 2em;
}

.manage-billing-info h4 {
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 700;
    margin: 0;
}

.manage-billing-info p {
    color: var(--quaternary-color);
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0;
    max-width: 225px;
    line-height: 1.8;
}

.manage-billing-tab-sec .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--primary-color);
}

.manage-billing-tab-sec .nav-pills .nav-link {
    background-color: #E1E1E1;
    color: var(--primary-color);
}

.manage-billing-tab-sec .nav-pills {
    gap: 1em;
}

.manage-billing-action-btn .edit-btn {
    background-color: transparent !important;
    padding: 0 !important;
    border: 0 !important;
}

.manage-billing-action-btn .delete-btn {
    background-color: transparent !important;
    padding: 0 !important;
    border: 0 !important;
}

.manage-billing-action-btn-sec {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.manage-billing-action-btn {
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: flex-end;
}

.new-billing-form-sec {
    margin-top: 2em;
}

.manage-billing-info li {
    list-style-type: none;
}

.manage-billing-info li {
    color: var(--quaternary-color);
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0;
    max-width: 225px;
    line-height: 1.8;
}

.checkbox.label-bg input {
    opacity: 0;
    position: absolute;
}

.checkbox.label-bg .checkbox label {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 2;
    appearance: none;
    -webkit-appearance: none;
}

.label-bg label {
    background-color: var(--secondary-color) !important;
}

.checkbox.label-bg label:before {
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    -webkit-font-smoothing: antialiased;
    content: "\f111";
    display: inline-block;
    font-family: Font Awesome\ 5 Free;
    font-size: 24px;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    padding: 0;
    position: absolute;
    right: 55px;
    text-rendering: auto;
    top: 21px;
    transition: .3s;
    z-index: 9;
}

.checkbox.label-bg input[type=radio]:checked+label:before {
    color: var(--primary-color);
    content: "\f058";
}

.checkbox.label-bg input[type=radio]:checked+label:before,
.checkbox.label-bg label:before {
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

/* span.text-danger.card-delete.del {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    position: absolute;
    right: 58px;
    top: -250px;
} */

.manage-billing-box label{
    width: 100%;
}

.manage-billing-card ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}