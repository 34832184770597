.category-list-sec {
    background-color: #252525;
}

.category-list-box ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: center;
    overflow: auto;
}

.category-list-box ul li {
    display: inline-block;
    padding: 1em 2em;
    text-align: center;
}

.category-list-box ul li.active {
    background-color: #404040;
}

.category-list-box ul li a {
    font-size: 0.8em;
    color: var(--secondary-color);
    font-weight: 600;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    white-space: nowrap;
}

.category-list-box ul li a:hover{
    text-decoration: underline!important;
    color: rgb(203 129 97);
}


.banner-slider-card {
    display: flex;
    gap: 2em;
    background-color: #F8F8F0;
    height: calc(100vh - 200px);
}

.banner-slider-content {
    width: 55%;
    padding-left: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.banner-slider-img {
    width: 100%;
}

.banner-slider-img-sec {
    width: 45%;
}

.banner-slider-content h1 {
    font-size: 3.5em;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 0.2em;
}

.banner-slider-content p {
    color: var(--quaternary-color);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.8;
    max-width: 600px;
    margin-bottom: 1em;
}

.banner-sec .slick-dots {
    bottom: 50px;
    padding-right: 15em;
}

/* .banner-sec .slick-dots li {
    margin: 0 0.25rem;
  } */
.banner-sec .slick-dots button {
    display: block;
    width: 1rem;
    height: 1rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-indent: -9999px;
}

.banner-sec .slick-dots li.slick-active button {
    background-color: rgb(180 180 180);
    padding: 3px;
    border: 1px solid var(--quaternary-color);
}


.banner-sec .slick-next:before,
.slick-prev:before {
    color: var(--primary-color) !important;
    font-size: 30px !important;
}

.banner-sec .slick-prev {
    z-index: 1;
    left: 10px;
}

.banner-sec .slick-next {
    z-index: 1;
    right: 20px;
}

/* Favourites Product CSS */

.favourites-product-sec {
    padding: 4em 0;
}

.favourites-product-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2em;
    justify-content: center;
    height: 100%;
}

.favourites-product-content h5 {
    font-size: 1em;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0;
}

.favourites-product-content h2 {
    font-size: 2em;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 1em;
    line-height: 1.2;
    margin-bottom: 0;
}

.favourites-product-box {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 2em;
}

.favourites-product-card {
    background-color: #F6F6F6;
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    text-align: center;
    justify-content: center;
}

.favourites-product-img {
    height: 7em;
    object-fit: contain;
    aspect-ratio: 3/3;
    margin: auto;
}

.favourites-product-card a {
    color: var(--quaternary-color);
}

.favourites-product-info h4 {
    color: var(--quaternary-color);
    font-size: 0.9em;
    font-weight: 400;
    line-height: 1.8;
    margin-bottom: 0;
}

.new-arrivalst-sec {
    background-image: url('new-arrivals-bg.png');
    padding: 4em 0;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
}

.new-arrivalst-sec::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(30, 30, 30, 0.7);
}

.new-arrivals-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2em;
    position: relative;
    padding: 4em 0;
}

.new-arrivals-card h5 {
    color: var(--secondary-color);
    font-size: 1em;
    font-weight: 500;
    text-transform: uppercase;
}

.new-arrivals-card h2 {
    color: var(--secondary-color);
    font-size: 2em;
    font-weight: 700;
}

.new-arrivals-card .download-receipt-btn {
    border-color: var(--secondary-color) !important;
    color: var(--secondary-color) !important;
}

.new-arrivals-card .download-receipt-btn:hover,
.new-arrivals-card .download-receipt-btn:focus,
.new-arrivals-card .download-receipt-btn:active {
    border-color: var(--secondary-color) !important;
    color: var(--secondary-color) !important;
}

.features-product-sec.landing-feature-product-sec {
    padding: 4em 0;
}

.left-favourites-box {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 0em;
    background-color: #F8F8F0;
}

.left-favourites-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2em;
    padding-right: 4em;
    justify-content: center;
    text-align: right;
}

.left-favourites-content h5 {
    font-size: 1em;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0;
}

.left-favourites-content h2 {
    font-size: 2em;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 1em;
    line-height: 1.2;
    margin-bottom: 0;
}

.left-favourites-img {
    width: 100%;
}

.right-favourites-box {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 0em;
    background-color: #F8F8F0;
}

.right-favourites-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2em;
    padding-left: 4em;
    justify-content: center;
}

.right-favourites-content h5 {
    font-size: 1em;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0;
}

.right-favourites-content h2 {
    font-size: 2em;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 1em;
    line-height: 1.2;
    margin-bottom: 0;
}

.right-favourites-img {
    width: 100%;
}

/* Customer testimonials CSS */

.customer-testimonials-sec {
    padding: 4em 0 5em;
}

.customer-testimonilas-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2em;
    padding: 2em;
    transition: all .5s ease-in-out;
}

.customer-testimonilas-card:hover{
    background-color: #f4f4f4;
}

.customer-testimonials-user-img {
    width: 5em;
    height: 5em;
    border-radius: 50%;
    object-fit: cover;
    margin: auto;
}

.customer-star-rating-sec {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.customer-card-info p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.customer-star-rating-sec svg:nth-child(1) {
    fill: #FFA525;
}

.customer-star-rating-sec svg:nth-child(2) {
    fill: #FFA525;
}

.customer-star-rating-sec svg:nth-child(3) {
    fill: #FFA525;
}

.customer-star-rating-sec svg:nth-child(4) {
    fill: #FFA525;
}

.customer-testimonials-info h4 {
    font-size: 1.2em;
    font-weight: 400;
    color: var(--primary-color);
    margin-bottom: 0.5em;
}

.customer-testimonials-info h4 a {
    color: var(--primary-color);
}

.customer-testimonials-info p {
    color: var(--quaternary-color);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.8;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.customer-testimonials-user-info h5 {
    font-size: 1em;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 1em;
}

.favourites-product-card{
    position: relative;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.favourites-product-card:hover {
    -webkit-transform: translate3d(0,-10px,0);
    -o-transform: translate3d(0,-10px,0);
    -ms-transform: translate3d(0,-10px,0);
    -moz-transform: translate3d(0,-10px,0);
    transform: translate3d(0,-10px,0);
  }