
.order-details-wrapped {
    display:flex;
    gap: 1em;
    width: 100%;
    padding: 1em 0;
}
.order-details-filter {
    height: 100%;
    width: 23%;
    /* float: left; */
    border: 1px solid #EDF0F1;
    overflow: hidden;
    border-radius: 16px;
}

.order-history-details {
    height: 100%;
    width: 77%;
    /* float: left; */
    border: 1px solid #EDF0F1;
    border-radius: 16px;
    padding: 1.5em;
    margin: 0 .2em;
}

.order-filter-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #efefef;
    padding: 1em;
}

.order-filter-head h3 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0;
}

.filter-clear-btn button {
    background-color: unset !important;
    border: none !important;
    color: var(--tertiary-theme) !important;
    font-weight: 600;
    font-size: 0.9em;
}

.form-check-input {
    height: 1em !important;
    width: 1em !important;
    cursor: pointer;
}
.form-check-inline{
    line-height: 1.8;
    margin-bottom: .5em !important;
}
.form-check-input:checked {
    background-color: var(--tertiary-theme) !important;
    border-color: var(--tertiary-theme) !important;
}

.filter-check-wrapped{
    padding: 1.5em 1em;
}

/* .filter-check-wrapped+.filter-check-wrapped{
    padding-top: 0;
} */

.track-product-wrapped{
    padding: 2em 0;
}

.filter-check-wrapped .pretty{
    margin-top: 1em;
}

.filter-check-wrapped h4, .ilter-check-wrapped h4 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 1em;
}

.order-history-title h4 {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0.5em!important;
}

.order-history-title .desc{
    font-size: 0.8em;
    color: #00233399;
    margin-bottom: 0;
}

.order-history-title span {
    font-size: 0.8em;
    color: var(--secondary-text);
}

.order-history-head, .history-status-wrapped {
    display: flex;
    align-items:flex-end;
    justify-content: space-between;
    gap: 2em;
}
.order-history-head .search-frame-head{
    width: 100%;
}
.order-history-head h4{
    flex: 1 1;
    margin-bottom: 0em!important;
}
.order-history-head h4 a{
    font-size: 0.8em;
    font-weight: 600;
    margin-bottom: 0em; 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    color: var(--primary-text);
}
.order-history-head span a{
    font-size: 0.8em;
    color: #5D5D5D;
}
.orders-history-card-info .product-name{
    color: var(--tertiary-theme);
    font-weight: 500;
    padding: .5em 0;
    display: inline-block;
}
.history-status-wrapped-left h5{
    color: var(--color);
    font-size: 0.8em;
    font-weight: 600;
    margin-bottom: 0.5em;
}
.history-status-wrapped-left p{
    font-size: 0.8em;
    color: var(--secondary-text);
    margin-bottom: 0;
}
.orders-history-card-img {
    width: 12em;
    height: 10em;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #EDF0F1;
}
.orders-history-card-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.orders-history-card{
    /* display: flex;
    align-items: center;
    gap: 1em; */
    display: grid;
    grid-template-columns: 215px auto;
    border: 1px solid #EDF0F1;
    border-radius: 16px;
    padding: 1em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}
.orders-history-card:last-child{
    margin-bottom: 0em;
}
.history-status-wrapped-right span{
    font-size: 0.8em;
    color: var(--tertiary-theme);
    display: flex;
    gap: 0.5em;
    position: relative;
}
.history-status-wrapped-right span a{
    color: var(--tertiary-theme);
}
.history-status-wrapped-right span:last-child::before{
    display: none;
}
.history-status-wrapped-right span::before{
    content: '|';
    position: absolute;
    right: -10px;
}
.history-status-wrapped-right{
    display: flex; 
     gap:1em;
    /* align-items: center; */
    /* flex: 1 1; */
}




/* TrackProduct   */
.arriving-status-wrapped{
    margin-top: .5em;
    border: 1px solid #EAEAEF;
    padding: 2em 0.5em;
    border-radius: 24px;
    margin-bottom: 2em;
}
.arriving-track-grape{
    padding: 1em 0em 2em 0;
}
.arriving-status-wrapped h3{
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 1em;
}
.top-grap-title{
    color: #00233399;
    font-size: 1em;
    font-weight: 500;
}
.bottom-grap-title{
    font-size: 0.85em;
    font-weight: 500;
    white-space: nowrap;
    position: absolute;
    bottom: -40px;
}
.arriving-track-grape ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2em;
}
.arriving-track-grape ul li{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    flex: 1 1;
    position: relative;
}
.arriving-track-grape ul li .grap-tick{
    position: relative;
}
.arriving-track-grape ul li .grap-tick::after {
    content: ' ';
    background-color: #efefef;
    min-width: 200px;
    height: 2px;
    position: absolute;
    display: inline-block;
    left: 48px;
    top: 12px;
}
.arriving-track-grape ul li:last-child .grap-tick::after{
display: none;
}
.arriving-track-grape ul li.active .grap-tick::after{
    background-color:var(--tertiary-theme);
}
.arriving-track-grape ul li.active .grap-tick svg g rect{
    fill:var(--tertiary-theme);
}
.arriving-track-grape ul li.active .top-grap-title{
    color:var(--tertiary-theme);
    font-weight: 500;
}
/* .address-details-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
} */
.address-details-head h4{
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 1em;
}
.address-full-details h4{
    font-size: 1em;
    font-weight: 600;
    color: var(--tertiary-theme);
    /* margin-bottom: 1em; */
}
.address-details-head span a{
    font-size: 1em;
    font-weight: 600;
    color: var(--tertiary-theme);
    /* margin-bottom: 1em; */
}
.address-full-details p{
 line-height: 1.5;
 width: 100%;
 margin-bottom: .5em;
 color: var(--secondary-text);
word-break: break-word;
white-space: pre-line;
overflow-wrap: break-word;
-ms-word-break: break-word;
word-break: break-word;
-ms-hyphens: auto;
-moz-hyphens: auto;
-webkit-hyphens: auto;
hyphens: auto;
}
.address-full-details .address-number span{
 color: #333;
 font-weight: 600;
}
.address-details-wrap {
    border: 1px solid #EAEAEF;
    padding: 1.5em;
    border-radius: 16px;
    margin: 1em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-history-wrapped{
    padding: 2em 0;
}

.search-order-history-sec .form-control{
    border: 1px solid #0000001A!important;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-right: 0!important;
    height: 45px;
}

.search-order-history-sec .input-group{
    min-width: 23em;
}

.search-order-history-sec .input-group-text{
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-left: 0!important;
    border: 1px solid #0000001A!important;
    background-color: transparent!important;
}
.progress-items.cancel {
    color: red;
  }

  .progress-items.cancel.active .grap-tick svg g rect {
    fill: red;
}

.progress-items.return {
    color: orange;
  }
  
  .progress-items.return.active .grap-tick svg g rect {
    fill: orange;
  }
  
  .history-status-wrapped-left .cancel-text-btn
{
  color: orange !important;
}
.history-status-wrapped-left{
    flex: 1 1;
}
.track-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tracking-section {
    gap: 1em;
    display: flex;
}
.tracking-section button.default-btn.btn.btn-primary {
    background-color: var(--primary-theme) !important;
}
.tracking-section button.download-receipt-btn.btn.btn-primary {
    background-color: var(--secondary-theme) !important;
    color: #fff !important;
    font-weight: 700 !important;
    border: 0px !important;
}
.clear-search-btn
{
    cursor: pointer;
}