.product-search-sec {
  padding: 3em 0;
}

.product-search-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.product-search-left {
  display: block;
  height: 100%;
  margin-left: 0;
  margin-right: 2em;
  transition: all 2s ease;
  width: 30%;
  }

.product-search-right {
  width: 100%;
}

.product-search-left-card {
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 10px;
  margin-bottom: 2em;
}

.product-search-header-card {
  background: #f5f5f5;
  border-radius: 10px 10px 0 0;
  display: flex;
  font-size: 1em;
  font-weight: 700;
  justify-content: space-between;
  padding: 1em
}

.product-search-header-card a {
  color: var(--primary-color);
  font-size: 0.9em;
  font-weight: 500;
  text-decoration: none;
}

.product-search-left .btn-categories {
  align-items: center;
  background: transparent !important;
  border: 0 !important;
  color: var(--tertiary-color) !important;
  display: flex;
  font-size: 0.8em !important;
  font-weight: 500 !important;
  justify-content: space-between;
  padding: 1em !important;
  width: 100%;
}

.product-search-left .btn-categories svg {
  width: 0.8em;
}

.catgories-availablity-sec {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
}

.catgories-availablity-sec input[type=checkbox] {
  position: relative;
  width: 1.2em;
  height: 1.2em;
  color: #363839;
  border: 1px solid #bdc1c6;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

.catgories-availablity-sec input[type=checkbox]::before {
  position: absolute;
  content: "";
  display: block;
  top: 0px;
  left: 5px;
  width: 8px;
  height: 13px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.catgories-availablity-sec input[type=checkbox]:checked {
  color: #fff;
  border-color: #06842c;
  background: #06842c;
}

.catgories-availablity-sec input[type=checkbox]:checked::before {
  opacity: 1;
}

.catgories-availablity-sec input[type=checkbox]:checked~label::before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.catgories-availablity-sec label {
  position: relative;
  cursor: pointer;
  font-size: 0.85em;
  font-weight: 400;
  color: var(--quaternary-color);
  padding: 0 0.25em 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.catgories-availablity-sec label::before {
  position: absolute;
  content: attr(data-content);
  color: #9c9e9f;
  -webkit-clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  text-decoration: line-through;
  text-decoration-thickness: 3px;
  -webkit-text-decoration-color: #363839;
  text-decoration-color: #363839;
  transition: -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.catgories-availablity-sec .custom-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.product-size-list-sec {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  gap: 0.5rem;
  padding: 1em !important;
  margin-bottom: 0;
}

.product-size-list-sec li {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
}

.product-size-list-sec li a {
  color: var(--quaternary-color);
  font-weight: 600;
  font-size: 0.9em;
}

.product-prize-list-sec {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 1rem;
  padding: 1em !important;
  margin-bottom: 0;
}

.product-prize-list-sec li {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
}

.product-prize-list-sec li a {
  color: var(--quaternary-color);
  font-weight: 600;
  font-size: 0.9em;
}


.product-type-list-sec {
  display: flex;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
  padding: 1em !important;
  margin-bottom: 0;
}

.product-type-list-sec li {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  font-size: 0.85em;
  font-weight: 500;
}

.product-type-list-sec li a {
  color: var(--quaternary-color);
  font-weight: 600;
  font-size: 1em;
  font-weight: 600;
}

.product-search-sortby-card {
  align-items: center;
  display: flex;
  gap: 2em;
}

.product-search-sortby-sec {
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  /* margin: 0 0 2em; */
  padding: 1em 2em;
  width: 100%;
}

.product-search-sortby-sec h4 {
  color: var(--tertiary-color);
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
}

.product-search-sortby-card h4 {
  color: var(--tertiary-color);
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
  white-space: nowrap;
}

.product-list-box {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  gap: 1rem;
}

.product-list-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1em;
  position: relative;
}

.product-rating-star {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.product-rating-sec {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.product-list-info .product-name {
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 0;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.product-list-info .product-amount {
  font-size: 1.6em;
  font-weight: 900;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.product-rating-count {
  color: var(--quaternary-color);
}

.product-list-info {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.product-list-img {
  height: 12em;
  object-fit: contain;
  aspect-ratio: 3/3;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: all 1s;
}

.product-list-img:hover{
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -o-transition: 1s;
    transition: all 1s;
}

.product-list-btn-sec {
  margin-top: 0.5em;
}

/* Single Product CSS */

.single-product-full-img-sec .image-gallery-swipe .image-gallery-slides {
  background: var(--primary-color);
}

.image-gallery-thumbnail {
  background: var(--primary-color);
  /* border: 0 !important; */
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: 6em;
  height: 6em;
  object-fit: cover;
  /* object-fit: contain; */
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  display: flex;
  gap: 0.5em;
  align-items: center;
  flex-direction: column;
}

.single-product-full-img-sec .image-gallery-play-button {
  display: none;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 40px;
  width: 40px;
  stroke: var(--secondary-color);
}

.image-gallery-fullscreen-button .image-gallery-svg {
  stroke: var(--secondary-color);
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  /* height: 32em; */
  object-fit: contain;
  height: calc(100vh - 220px);
}

.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
  height: 100%;
}

.image-gallery-content.fullscreen .image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: 6em;
  height: 6em;
  object-fit: cover;
}

.single-product-sec {
  padding: 3em 0;
}

.single-product-review-card {
  display: flex;
  align-items: center;
  gap: 2em;
}

.single-product-info-sec .rating-star {
  margin-bottom: 0;
}

.single-product-info-sec h4 {
  font-size: 1.4em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
  padding: 0.5em 0;
}

.single-product-review-card .write-review-link {
  font-size: 1.1em;
  font-weight: 400;
  color: var(--tertiary-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.single-product-info-sec h3 {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.8em;
  margin-bottom: 0.5em;
}

.stock-availability h5 {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.stock-availability h5 span {
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 600;
  color: var(--quaternary-color);
}


.single-product-info-sec h3 sub {
  bottom: 0;
}

.single-product-info-sec h3 del {
  color: var(--quaternary-color);
  font-weight: 500;
  font-size: 0.8em;
}

.single-product-review-card {
  padding: 0.6em 0;
}

.single-product-info-sec p {
  color: var(--quaternary-color);
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 1em;
  word-break: break-all;
}

.single-product-info-sec p span {
  color: var(--tertiary-color);
}

.add-wishlist-sec svg {
  width: 1.8em;
}

.add-wishlist-sec {
  font-size: 1.1em;
  font-weight: 400;
  color: var(--tertiary-color);
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.5em 0;

}

.progress-bar-sec {
  padding: 0.5em 0;
}

.progress-bar-sec h5 {
  font-size: 1.1em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom: 1em;
}

.progress-bar-sec .progress {
  border-radius: 20px;
}

.progress-bar {
  background: linear-gradient(180deg, #FF4313 0%, #F6871D 50.83%, #EECE27 100%);
}

.single-product-quantity-sec {
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

.single-product-quantity-sec .form-control:focus {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%) !important;
}

.categories-search-price-card .form-control:focus {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%) !important;
}

.single-product-quantity-sec .react-numeric-input .form-control {
  border: 1px solid #A4A4A4;
  width: 6em;
}

.single-product-quantity-sec .react-numeric-input b {
  background-color: transparent !important;
  border: 0 !important;
}

.single-product-quantity-sec .react-numeric-input i {
  background: var(--primary-color) !important;
}

.single-product-quantity-sec p {
  display: flex;
  align-items: center;
  gap: 1em;
}

.single-product-action-btn-sec .add-to-cart-btn {
  background-color: #232323 !important;
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  color: var(--secondary-color) !important;
  display: inline-block;
  border: 1px solid #232323 !important;
  font-size: 1.2em;
  font-weight: 500;
}

.single-product-action-btn-sec .add-to-cart-btn:hover,
.single-product-action-btn-sec .add-to-cart-btn:focus,
.single-product-action-btn-sec .add-to-cart-btn:active {
  background-color: #232323 !important;
  color: var(--secondary-color) !important;
  box-shadow: none !important;
}

.single-product-action-btn-sec {
  display: flex;
  align-items: center;
  gap: 1em;
}

.hot-sale-sold-sec {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 1em 0;
}

.hot-sale-sold-sec p {
  color: #F3705A;
  margin-bottom: 0;
}

.people-view-count-sec {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.people-view-count-sec p {
  color: var(--primary-color);
  margin-bottom: 0;
  font-weight: 600;
  font-size: 0.9em;
}

.add-wishlist-btn {
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
  padding: 0.5em 3em !important;
  color: var(--secondary-color) !important;
}

.heart-icon-sec {
  border: 1px solid #A4A4A4;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
  width: 6em;
  text-align: center;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border-color: #dedddd !important;
}

.image-gallery-thumbnail:hover {
  border-color: #dedddd !important;
}

.single-product-review-sec {
  margin-top: 2em;
}

.single-product-description-sec p {
  color: var(--quaternary-color);
  font-size: 1em;
  font-weight: 400;
  line-height: 1.8;
}

.single-product-description-sec {
  padding-top: 1.5em;
}

.single-product-description-sec h4 {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.5;
}

.single-product-tab-sec .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--primary-color);
}

.single-product-tab-sec .nav-pills .nav-link {
  background-color: #E1E1E1;
  color: var(--primary-color);
}

.single-product-tab-sec .nav-pills {
  gap: 1em;
  justify-content: center;
}

.single-product-review-box {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 2em;
  margin-top: 2em;
}

.single-product-review-left {
  grid-template-columns: 85px auto;
  display: grid;
}

.single-product-review-user-img {
  width: 4em;
  height: 4em;
  object-fit: cover;
  border-radius: 50%;
}

.single-product-review-info p {
  font-size: 0.9em;
  color: var(--quaternary-color);
  margin: 0;
  font-weight: 400;
  line-height: 1.8;
  padding: 0.5em 0;
}

.single-product-review-info h4 {
  font-size: 1em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.single-product-info-sec {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.single-product-review-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2em;
}

.single-product-review-btn-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

/* Features product CSS */

.features-product-sec {
  padding-top: 3em;
}

.feature-product-list-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-top: 4em;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1em;
  position: relative;
}

.feature-product-rating-star {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.feature-product-rating-sec {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.feature-product-list-info .product-name {
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 0;
}

.feature-product-list-info .product-amount {
  font-size: 1.8em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.feature-product-rating-count {
  color: var(--quaternary-color);
}

.feature-product-list-info {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.feature-product-list-img {
  height: 10em;
  object-fit: contain;
  aspect-ratio: 3/3;
}

.feature-product-list-btn-sec {
  margin-top: 0.5em;
}

.feature-product-offer-sec {
  background-color: #FF4E00;
  position: absolute;
  left: 0;
  top: 25px;
  font-size: 0.9em;
  padding: 0.5em;
  font-weight: 400;
  color: var(--secondary-color);
}

.feature-wishlist-heart-icon-sec {
  position: absolute;
  right: 20px;
  top: 35px;
  font-size: 0.9em;
}

.feature-product-list-btn-sec .default-btn {
  width: 200px;
}

.single-product-review {
  color: #ccc;
  display: flex;
  align-items: center;
  gap:0.5em;
}

.product-offer-amount-sec .del{
  font-size: 1.2em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
}

.product-offer-amount-sec{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5em;
}

.btn-theme-type-btn{
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  background-color: transparent!important;
  border-radius: 5px!important;
  padding: 0.5em;
  text-align: center;
  font-size: 0.85em;
  font-weight: 500;
  color: var(--primary-color)!important;
  min-width: 4em;
  transition: unset!important;
}

.btn-theme-type-btn:hover,
.btn-theme-type-btn:focus,
.btn-theme-type-btn:active{
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  background-color: transparent!important;
  color: var(--primary-color)!important;
}

.material-btn-box .btn-theme-type-btn.active{
  background-color:  #E1E1E1!important;
}

.material-btn-box{
  display: flex;
  align-items: center;
  gap: 0.5em;
  flex-wrap: wrap;
  text-align: center;
}

.material-btn-sec{
  display: flex;
  align-items: flex-start;
  gap:0.5em;
}

.size-btn-box .btn-theme-type-btn.active{
  background-color:  #E1E1E1!important;
}

.btn-theme-type-btn{
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  background-color: transparent!important;
  border-radius: 5px!important;
  padding: 0.5em;
  text-align: center;
  font-size: 0.85em;
  font-weight: 500;
  color: var(--primary-color)!important;
  min-width: 4em;
  transition: unset!important;
}

.btn-theme-type-btn:hover,
.btn-theme-type-btn:focus,
.btn-theme-type-btn:active{
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  background-color: transparent!important;
  color: var(--primary-color)!important;
}

.size-btn-box{
  display: flex;
  align-items: center;
  gap: 0.5em;
  flex-wrap: wrap;
}

.size-btn-sec{
  display: flex;
  align-items: flex-start;
  gap:0.5em;
}

.weight-btn-box .btn-theme-type-btn.active{
  background-color:  #E1E1E1!important;
}

.btn-theme-type-btn{
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  background-color: transparent!important;
  border-radius: 5px!important;
  padding: 0.5em;
  text-align: center;
  font-size: 0.85em;
  font-weight: 500;
  color: var(--primary-color)!important;
  min-width: 4em;
  transition: unset!important;
}

.btn-theme-type-btn:hover,
.btn-theme-type-btn:focus,
.btn-theme-type-btn:active{
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  background-color: transparent!important;
  color: var(--primary-color)!important;
}

.weight-btn-box {
  background: rgba(255, 195, 0, 0.08);
  padding: 0.5em 2em;
  border-radius: 50px;
 flex: 1 1;
  text-align: center;
}
.weight-btn-sec{
  display: flex;
  align-items: flex-start;
  gap:0.5em;
}

.product-review-sec{
  padding: 4em 0;
}

.product-review-sec .single-product-review-item{
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2em;
}

.product-sortby-icon-sec{
  display: none;
}

.desktop-none{
  display: none!important;
}

.product-search-left.mobile-product-search-left{
  display: none;
}



/* New Single Product CSS  */

.new-single-product-thumbnails-img{
  height: 6em;
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
}

.slider-thumbnails-sec .slick-slide{
  padding: 5px;
}

.new-single-product-img .magnifier-image{
  height: calc(100vh - 230px);
  object-fit: contain;
  width: 100%;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
  padding: 2em;
  background-color: #cb8161;
}

.new-single-product-box{
  padding-top: 1em;
}

.new-single-product-info-sec{
  padding-top: 1em;
}

.new-single-product-info-sec h4{
  color: var(--tertiary-color);
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.new-single-product-info-sec h3{
  color: var(--primary-color);
  font-size: 1.8em;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.magnifying-glass{
  border-width: 0!important;
}

.new-single-product-size li {
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 5px;
  font-size: .85em;
  font-weight: 600;
  padding: 0.5em 1em;
  text-align: center;
}

.new-single-product-size {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 0;
  padding: 0.5em 0;
}


.new-single-product-metal-type-box{
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 1rem;
  /* align-items: center;
  display: flex;
  flex-wrap: wrap; */
  gap: 0em;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 0.8px solid #CCCCCC;
}

.new-single-product-metal-type-card h4{
  color: #E99B40;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0.2em;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.new-single-product-metal-type-card p{
  color: var(--tertiary-color);
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.new-single-product-metal-type-card{
  border-right: 0.8px solid #CCCCCC;
  padding: 0em 1em;
}

.new-single-product-metal-type-card:first-child{
  padding-left: 0;
}

.new-single-product-metal-type-card:last-child{
  border-right: 0;
}

.new-single-product-star{
  display: flex;
  align-items: center;
  gap: 0.2em;
}

.new-single-product-rating-sec{
  display: flex;
  align-items: center;
  gap: 2em;
}

.new-single-product-star{
  margin-bottom: 0;
}

.new-single-product-rating-count-sec{
  color: rgba(219, 219, 219, 1);
}

.new-single-product-rating-count-sec span{
  color: #E99B40;
}

.new-single-product-description-sec h4{
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0.2em;
  margin-top: 0;
  color: #0A2C74;
}

.new-single-product-rating-sec{
  margin-bottom: 1em;
}

.new-single-product-description-sec p {
  color: var(--tertiary-color);
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
}

.show-more-btn {
  color: #cb8161!important;
  background-color: transparent!important;
  border: 0!important;
  padding: 0!important;
  font-size: 1.2em;
  font-weight: 500;
  margin-top: 0.2em;
  text-decoration: underline!important;
}

.show-more-btn:hover,
.show-more-btn:focus,
.show-more-btn:active {
  box-shadow: none !important;
}

.new-single-product-action-btn{
  margin: 1em 0;
  display: flex;
  align-items: center;
  gap: 1em;
}

.single-product-shop-by-collection-sec{
  padding-top: 4em;
}

.rating-link{
  display: flex;
  align-items: center;
  gap:0.5em;
}

.customer-review-modal.write-review-modal .modal-header .h4{
  font-size: 1em;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.customer-review-modal.write-review-modal .modal-header .h4 span{
  margin-top: 0.5em;
}

.customer-review-messages-card{
  background: var(--secondary-color);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 1em;
}

.customer-review-user-info h4{
  color: #0A2C74;
  font-size: 0.9em;
  font-weight: 600;
}

.customer-review-user-info p{
  color: var(--tertiary-color);
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.customer-review-messages-header-sec{
  grid-template-columns: 50px auto;
  display: grid;
}

.customer-review-messages-rating-sec{
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.5em 0;
}

.customer-review-messages-description p{
  color: var(--tertiary-color);
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0;
}

.customer-write-review-footer-sec{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.customer-write-review-header-sec h4{
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 0em;
  margin-top: 0;
  color: #0A2C74;
}

.customer-write-review-header-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8em 0;
}

.customer-review-messages-box{
  height: calc(50vh - 150px);
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1em;
  padding: 1em;
  padding-right: 2em;
}

.product-card-info h5{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.product-card-info p{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.single-product-full-img .image-gallery-swipe .image-gallery-slides {
  background: var(--primary-color);
}
.single-product-full-img .image-gallery-play-button {
  display: none;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  height: calc(100vh - 220px);
  object-fit: cover;
}
.product-out-stock-text
{
  color: #ff0000
}
.btn-theme-type-btn.weight
{
border: 0px !important;
}

.btn-theme-type-btn.active {
  background-color: #E1E1E1!important;
  border-radius: 50px !important;
}