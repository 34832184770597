
.order-placed-sec{
    padding: 3em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height:70vh;
}
.order-placed-header{
   
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
}

.order-placed-info h2{
    font-size: 1.5em;
    font-weight: 700;
    color: var(--primary-color);
}   
.order-placed-info p{
    font-size: 1em;
    color: var(--quaternary-color);
    font-weight: 400;
    margin-bottom: 0;
 
}
.order-placed-info span{
    font-size: 1em;
    color: #7C8C92;
    padding-top: 2em;
}
.order-placed-img-sec{
    justify-content: center;
    align-items: center;
}

.order-placed-img{
    width: 10em;
    margin: auto;
}

.order-place-card{
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.order-placed-info{
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    justify-content: center;
    text-align: center;
}