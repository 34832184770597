.new-header-nav-center{
    background-color: #F2E9E9;
}

.new-header-nav-center .logo{
    height: 2.5em;
}

.new-header-search-sec .form-control{
    background-color: var(--secondary-color);
    border: 0!important;
    min-width: 400px!important;
}

.new-header-search-sec .form-control:focus{
    box-shadow: none!important;
}

.new-header-nav-center .input-group-text{
    background-color: var(--secondary-color);
    border: 0!important;
}

.new-header-nav-center .navbar-expand-lg .navbar-nav{
    gap: 2.5em;
    align-items: center;
}

.new-header-nav-center .navbar-expand-lg .navbar-nav a{
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: .9em;
    font-weight: 600;
}

.new-landing-categories-box ul{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-bottom: 0;
}

.new-landing-categories-box ul li a{
    color: var(--tertiary-color);
}

.new-landing-categories-box ul li a:hover {
    color: #cb8161;
    text-decoration: underline!important;
}

.new-landing-categories-box ul li {
    display: inline-block;
    padding: 1em 2em;
    text-align: center;
}

.new-banner-slider-img{
    height:  calc(100vh - 122px);
    width: 100%;
    object-fit:cover;
}

.arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
  }
  
  .arrow--left {
    left: 5px;
  }
  
  .arrow--right {
    left: auto;
    right: 5px;
  }
  
  .arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }

  .new-banner-slider-info h1 {
    font-size: 3.5em;
    font-weight: 700;
    color: var(--secondary-color);
    margin-bottom: 0.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-width: 600px;
}

.new-banner-slider-info p {
    color: #ffffcccc;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.8;
    max-width: 600px;
    margin-bottom: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-width: 600px;
}

.new-landing-banner-slider-sec{
    position: relative;
}

.new-banner-slider-img-sec:before {
    position: absolute;
    content: "";
    background: linear-gradient(to left,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.new-banner-slider-info{
    position: absolute;
    top: 50%;
    z-index: 9;
    transform: translateY(-50%);
    left: 5%;
}

.border-style-line {
    overflow: hidden;
    text-align: center;
    margin: 20px;
}

.border-style-line span {
    text-align: center;
    font-size: 15px;
    color: #e0e0e0;
    display: inline-block;
    position: relative;
    padding: 0 25px;
    z-index: 1;
}

.border-style-line span:before {
    display: inline-block;
    content: "";
    height: 2px;
    width: 100%;
    background-color: #cfcfcf;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
}

.border-style-line span:after {
    display: inline-block;
    content: "";
    height: 2px;
    width: 100%;
    background-color: #cfcfcf;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
}

.shop-by-category-card{
    background: var(--secondary-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin-top: 2em;
}

.shop-by-category-info{
    padding: 1em 1em;
}

.shop-by-category-info h4 {
    font-size: .9em;
    font-weight: 400;
    line-height: 1.8;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.shop-by-category-box a{
    color: var(--primary-color);
}

.shop-by-category-box a:hover{
    color: #cb8161;
}

.shop-by-category-img{
    height: 10em;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.shop-by-collection-card{
    background: var(--secondary-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin-top: 2em;
}

.shop-by-collection-info{
    padding: 1em 1em;
}

.shop-by-collection-info h4 {
    font-size: .85em;
    font-weight: 400;
    line-height: 1.8;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.shop-by-collection-box a{
    color: var(--primary-color);
}

.shop-by-collection-box a:hover{
    color: #cb8161;
}

.shop-by-collection-img{
    height: 25em;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.shop-by-collection-info a{
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: .85em;
    font-weight: 400;
    line-height: 1.8;
    margin-bottom: 0;
}

.shop-by-collection-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-landing-sec .new-arrivalst-sec {
    background-image: url('new-arrivals.png');
    padding: 4em 0;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    border-radius: 26px;
}

.new-landing-sec .new-arrivalst-sec::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(30, 30, 30, 0.7);
    border-radius: 26px;
}

.top-sellers-info h4{
    font-size: .9em;
    font-weight: 400;
    line-height: 1.8;
    margin-bottom: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.top-sellers-info h3{
    color: var(--tertiary-color);
    font-size: 1.6em;
    font-weight: 900;
    margin-bottom: 0;
}

.top-sellers-box{
    display: grid;
    gap: 2em;
    grid-template-columns: repeat(4,1fr);
}

.top-sellers-card{
    background: var(--secondary-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: relative;
}

.top-sellers-img{
    width: 100%;
    height: 20em;
    object-fit: cover;
}

.top-sellers-item{
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1.2em;
}

.top-sellers-btn-sec .default-btn{
    width: 100%;
    text-align: center;
}

.top-sellers-badge{
    background-color: var(--primary-color);
    padding: 0.5em 2em;
    display: inline-block;
    color: var(--secondary-color);
    font-size: 0.9em;
    font-weight: 500;
    position: absolute;
    top: 15px;
    left: 0;
}


.top-sellers-sec .slick-list{
    padding: 1em 0;
}

.top-sellers-sec .slick-slide{
    padding: 10px;
}

.top-sellers-sec .slick-prev:before, 
.top-sellers-sec .slick-next:before{
    font-size: 30px;
    color: var(--primary-color);
}

.new-for-you-card{
    position: relative;
    background: var(--secondary-color);
    border-radius: 6px;
    margin-top: 2em;
}

.new-for-you-card::before{
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
    border-radius: 6px;
}


.new-for-you-btn-sec{
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.new-for-you-btn {
    border: 2px solid rgba(255, 255, 255, 0.8) !important;
    background-color: transparent !important;
    border-radius: 5px !important;
    color: var(--secondary-color) !important;
    font-size: 0.9em !important;
    font-weight: 500 !important;
    overflow: hidden;
    padding: 0.6em 1em !important;
    position: relative;
    text-align: center;
    transition: all .5s ease-in-out;
    display: inline-block;
}

.new-for-you-img{
    border-radius: 6px;
    width: 100%;
}

