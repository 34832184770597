.cart-sec {
    padding: 4em 0;
}

.section-title h1 {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin: auto;
}

.section-title {
    text-align: center;
    margin-bottom: 2em;
}

.section-title.go-back {
    text-align: center;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    gap: 1em;
}

.landing-page-sec .section-title {
    margin-bottom: 4em;
}

.cart-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.cart-header {
    background:#0E1036;
    border-radius: 5px 5px 0px 0px;
    padding: 1.2em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-body {
    padding: 2em;
    border-radius: 0 0 5px 5px;
}

.cart-header h4 {
    color: var(--secondary-color);
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0;
}

.cart-list-card {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    padding: 1.5em 0;
    width: 100%;
}

.checkout-delivery-card .cart-list-card {
    padding: 0;
}

.cart-list-card:last-child {
    border-bottom: 0;
}

.cart-list-left-sec {
    align-items: center;
    display: flex;
    flex: 1 1;
    gap: 1.5em;
}

.cart-list-img {
    width: 8em;
    height: 8em;
    object-fit: inherit;
}
.cart-list-info p {
    color: var(--quaternary-color);
    font-size: 0.8em;
    font-weight: 400;
    margin-bottom: 0.5em;
    align-items: center;
    display: flex;
    gap: 1em;
}

.cart-list-info h4 {
    font-size: 0.9em;
    font-weight: 600;
}

.cart-list-info a {
    color: var(--tertiary-color);
}

.cart-list-card .react-numeric-input .form-control {
    border: 1px solid #A4A4A4;
    width: 6em;
}

.cart-list-card .react-numeric-input b {
    background-color: transparent !important;
    border: 0 !important;
}

.cart-list-card .react-numeric-input i {
    background: var(--primary-color) !important;
}

.cart-list-card .form-control:focus {
    box-shadow: none !important;
}

.cart-list-amout-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.cart-amount-info h4 {
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 600;
}

.cart-amount-info p {
    color: var(--tertiary-color);
    font-size: 0.9em;
    font-weight: 400;
}

.cart-action-btn-sec {
    align-items: center;
    display: flex;
    gap: 1em;
    justify-content: space-between;
}

.cart-list-right-sec {
    text-align: right;
}

.cart-summary-header {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 1em;
}

.cart-summary-header p {
    align-items: center;
    color: var(--quaternary-color);
    display: flex;
    font-size: 0.9em;
    font-weight: 400;
    justify-content: space-between;
}

.cart-summary-header p+p {
    margin-bottom: 0.5em;
}

.cart-summary-header p span {
    color: var(--tertiary-color);
}

.cart-summary-total-amount h5 {
    align-items: center;
    color: var(--tertiary-color);
    display: flex;
    font-size: 1em;
    font-weight: 600;
    justify-content: space-between;
}

.cart-summary-btn-sec {
    margin-top: 2em;
}

/* Checkout Address CSS */

.checkout-header-sec {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 2em;
}

.checkout-progress-bar {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0;
    position: relative;
}

.checkout-progress-bar li,
.checkout-flex {
    align-items: center;
    display: flex;
}

.checkout-flex {
    flex-direction: column;
    gap: 0.5em;
    text-align: center;
}

.checkout-progress-bar .active .count-no {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--secondary-color);
}

.checkout-progress-bar .count-no {
    align-items: center;
    background-color: #f2f2f2;
    border: 1px solid #74c1b9;
    border-radius: 50%;
    color: #74c1b9;
    display: flex;
    font-size: 1em;
    font-weight: 600;
    height: 2em;
    justify-content: center;
    width: 2em;
    z-index: 2;
}

.checkout-progress-bar .active h6 {
    color: var(--primary-color);
}

.checkout-progress-bar h6 {
    color: #74c1b9;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 0;
}

.checkout-progress-bar li:after,
.checkout-progress-bar li:before {
    border-bottom: 1px solid #74c1b9;
    content: "";
    left: 0;
    position: absolute;
    top: 16px;
    width: 100%;
    z-index: 1;
}


.checkout-new-address-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1em;
    margin-top: 2em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
}

.checkout-new-address-card h4 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0;
}

.checkout-address-modal .modal-close {
    background-color: transparent !important;
    padding: 0 !important;
    border: 0 !important;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 9;
}

.checkout-address-modal .modal-close .close-icon {
    width: 2em;
}

.checkout-address-modal .modal-close:focus {
    box-shadow: none !important;
    outline: none !important;
}

.checkout-address-modal .modal-dialog {
    max-width: 700px;
    border-radius: 10px;
}

.checkout-address-modal .modal-content {
    border-radius: 0px !important;
}

/* Checkout Summary CSS */

.checkout-delivery-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2em;
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: flex-start;
}

.checkout-delivery-address-info {
    display: flex;
    justify-content: space-between;
    gap: 1em;
    flex-direction: column;
}

.checkout-delivery-address-info h4 {
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 700;
    margin: 0;
}

.checkout-delivery-address-info p {
    color: var(--quaternary-color);
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0;
    max-width: 225px;
    line-height: 1.8;
}

.checkout-delivery-status {
    background: #E1E1E1;
    border-radius: 0px 0px 10px 10px;
    padding: 0.5em;
    text-align: center;
    font-size: 0.9em;
    font-weight: 600;
    color: var(--tertiary-color);
}

.checkout-summary-product-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0px 0px;
    padding: 0 2em;
    margin-top: 2em;
}

.checkout-delivery-continue-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1em 2em;
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout-delivery-continue-card h4 {
    font-size: 0.9em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 0;
}

.checkout-delivery-continue-card h4 span {
    color: var(--quaternary-color);
}

.wallet-icon-text span {
    font-size: 0.9em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 0;
}

.wallet-icon-text {
    display: flex;
    gap: 0.8em;
    align-items: center;
}